import { AssetAssignmentInfoT } from 'common/utils/assignment';
import { RequestStatusT } from 'common/utils/request-status';

import {
    AssignmentVehiclesSuggestItemT,
    AssignmentVehiclesT,
    AvailableVehiclesQueryT,
    CarrierT,
    FindTrailerQueryT,
    FindTruckQueryT,
    LinksT,
    ScheduleEventIdT,
    ScheduleEventT,
    SelfCostPredictionQueryT,
    SelfCostPredictionT,
    UserSelectionT,
} from './models';

export const INIT_USER_SELECTION = 'broker-admin/dispatch-assigment/INIT_USER_SELECTION';
export const CHANGE_USER_SELECTION = 'broker-admin/dispatch-assigment/CHANGE_USER_SELECTION';

export const FETCH_AVAILABLE_VEHICLES_REQUEST = 'broker-admin/dispatch-assigment/FETCH_AVAILABLE_VEHICLES_REQUEST';
export const FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN';
export const FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR';
export const FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS';

export const FETCH_AVAILABLE_CARRIERS_REQUEST = 'broker-admin/dispatch-assigment/FETCH_AVAILABLE_CARRIERS_REQUEST';
export const FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN';
export const FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR';
export const FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS =
    'broker-admin/dispatch-assigment/FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS';

export const REFRESH_TRAILER_REQUEST = 'broker-admin/dispatch-assigment/REFRESH_TRAILER_REQUEST';
export const FIND_TRAILER_REQUEST = 'broker-admin/dispatch-assigment/FIND_TRAILER_REQUEST';
export const FIND_TRAILER_REQUEST_BEGIN = 'broker-admin/dispatch-assigment/FIND_TRAILER_REQUEST_BEGIN';
export const FIND_TRAILER_REQUEST_ERROR = 'broker-admin/dispatch-assigment/FIND_TRAILER_REQUEST_ERROR';
export const FIND_TRAILER_REQUEST_SUCCESS = 'broker-admin/dispatch-assigment/FIND_TRAILER_REQUEST_SUCCESS';

export const REFRESH_TRUCK_REQUEST = 'broker-admin/dispatch-assigment/REFRESH_TRUCK_REQUEST';
export const FIND_TRUCK_REQUEST = 'broker-admin/dispatch-assigment/FIND_TRUCK_REQUEST';
export const FIND_TRUCK_REQUEST_BEGIN = 'broker-admin/dispatch-assigment/FIND_TRUCK_REQUEST_BEGIN';
export const FIND_TRUCK_REQUEST_ERROR = 'broker-admin/dispatch-assigment/FIND_TRUCK_REQUEST_ERROR';
export const FIND_TRUCK_REQUEST_SUCCESS = 'broker-admin/dispatch-assigment/FIND_TRUCK_REQUEST_SUCCESS';

export const PREDICT_SELF_COST_REQUEST = 'broker-admin/dispatch-assigment/PREDICT_SELF_COST_REQUEST';
export const PREDICT_SELF_COST_REQUEST_BEGIN = 'broker-admin/dispatch-assigment/PREDICT_SELF_COST_REQUEST_BEGIN';
export const PREDICT_SELF_COST_REQUEST_ERROR = 'broker-admin/dispatch-assigment/PREDICT_SELF_COST_REQUEST_ERROR';
export const PREDICT_SELF_COST_REQUEST_SUCCESS = 'broker-admin/dispatch-assigment/PREDICT_SELF_COST_REQUEST_SUCCESS';

export const ASSIGNMENT_REQUEST = 'broker-admin/dispatch-assigment/ASSIGNMENT_REQUEST';
export const ASSIGNMENT_REQUEST_BEGIN = 'broker-admin/dispatch-assigment/ASSIGNMENT_REQUEST_BEGIN';
export const ASSIGNMENT_REQUEST_ERROR = 'broker-admin/dispatch-assigment/ASSIGNMENT_REQUEST_ERROR';
export const ASSIGNMENT_REQUEST_SUCCESS = 'broker-admin/dispatch-assigment/ASSIGNMENT_REQUEST_SUCCESS';

export const FETCH_ASSET_SCHEDULE = 'broker-admin/dispatch-assigment/FETCH_ASSET_SCHEDULE';
export const FETCH_ASSET_SCHEDULE_BEGIN = 'broker-admin/dispatch-assigment/FETCH_ASSET_SCHEDULE_BEGIN';
export const FETCH_ASSET_SCHEDULE_ERROR = 'broker-admin/dispatch-assigment/FETCH_ASSET_SCHEDULE_ERROR';
export const FETCH_ASSET_SCHEDULE_SUCCESS = 'broker-admin/dispatch-assigment/FETCH_ASSET_SCHEDULE_SUCCESS';
export const SET_ASSET_SCHEDULE = 'broker-admin/dispatch-assigment/SET_ASSET_SCHEDULE';
export const SET_EVENT_LOAD_ROUTE = 'broker-admin/dispatch-assigment/SET_EVENT_LOAD_ROUTE';
export const SET_EVENT_ASSET_TRACK = 'broker-admin/dispatch-assigment/SET_EVENT_ASSET_TRACK';

export const SET_SHOW_SELECTED_ASSET_SCHEDULES = 'broker-admin/dispatch-assigment/SET_SHOW_SELECTED_ASSET_SCHEDULES';
export const SET_SELECTED_SCHEDULE_EVENT_ID = 'broker-admin/dispatch-assigment/SET_SELECTED_SCHEDULE_EVENT_ID';

export const INIT = 'broker-admin/dispatch-assigment/INIT';
export const RESET = 'broker-admin/dispatch-assigment/RESET';

export type ScheduleAssetT = {
    requestStatus: RequestStatusT;
    events: Array<ScheduleEventT>;
    routeById: Record<ScheduleEventIdT, Array<GooglePolylineT>>;
    trackById: Record<ScheduleEventIdT, Array<GeoPointT>>;
    currentEventId: ScheduleEventIdT | null;
};

export type DispatchAssigmentStateT = {
    userSelection: UserSelectionT;
    trailerById: Record<TrailerIdT, AssetAssignmentInfoT>;
    truckById: Record<TruckIdT, AssetAssignmentInfoT>;
    truckSuggest: {
        query: FindTruckQueryT | null;
        requestStatus: RequestStatusT;
        ids: Array<TruckIdT>;
    };
    trailerSuggest: {
        query: FindTrailerQueryT | null;
        requestStatus: RequestStatusT;
        ids: Array<TrailerIdT>;
    };
    availableVehicles: {
        query: AvailableVehiclesQueryT | null;
        requestStatus: RequestStatusT;
        list: Array<{
            trailerId: TrailerIdT | null;
            truckId: TruckIdT | null;
        }>;
    };
    currentPositionAssetById: Record<TrailerIdT | TruckIdT, GeoPointT>;
    scheduleAssetById: Record<TrailerIdT | TruckIdT, ScheduleAssetT>;
    links: LinksT;
    selfCostPrediction: {
        query: SelfCostPredictionQueryT | null;
        requestStatus: RequestStatusT;
        result: SelfCostPredictionT | null;
    };
    availableCarriers: {
        requestStatus: RequestStatusT;
        carriers: Array<CarrierT>;
    };
    assigmentStatus: RequestStatusT;
    isShowSelectedAssetSchedules: boolean;
    selectedScheduleEventId: ScheduleEventIdT | null;
};

export type InitUserSelectionActionT = {
    type: typeof INIT_USER_SELECTION;
    changes: Partial<UserSelectionT>;
};

export type ChangeUserSelectionActionT = {
    type: typeof CHANGE_USER_SELECTION;
    changes: Partial<UserSelectionT>;
    dispatchId: DispatchIdT | null;
};

export type FetchAvailableVehiclesActionT = {
    type: typeof FETCH_AVAILABLE_VEHICLES_REQUEST;
    query: AvailableVehiclesQueryT;
};

export type FetchAvailableVehiclesBeginActionT = {
    type: typeof FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN;
    query: AvailableVehiclesQueryT;
};

export type FetchAvailableVehiclesSuccessActionT = {
    type: typeof FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS;
    vehicles: AssignmentVehiclesT[];
    query: AvailableVehiclesQueryT;
};

export type FetchAvailableVehiclesErrorActionT = {
    type: typeof FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR;
    error: Error;
    query: AvailableVehiclesQueryT;
};

export type FetchAvailableCarriersActionT = {
    type: typeof FETCH_AVAILABLE_CARRIERS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchAvailableCarriersBeginActionT = {
    type: typeof FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN;
};

export type FetchAvailableCarriersSuccessActionT = {
    type: typeof FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS;
    carriers: CarrierT[];
};

export type FetchAvailableCarriersErrorActionT = {
    type: typeof FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR;
    error: Error;
};

export type RefreshTrailerActionT = {
    type: typeof REFRESH_TRAILER_REQUEST;
    query: FindTrailerQueryT;
};

export type FindTrailerActionT = {
    type: typeof FIND_TRAILER_REQUEST;
    query: FindTrailerQueryT;
};

export type FindTrailerBeginActionT = {
    type: typeof FIND_TRAILER_REQUEST_BEGIN;
    query: FindTrailerQueryT;
};

export type FindTrailerSuccessActionT = {
    type: typeof FIND_TRAILER_REQUEST_SUCCESS;
    trailersSuggestItems: AssignmentVehiclesSuggestItemT[];
    query: FindTrailerQueryT;
};

export type FindTrailerErrorActionT = {
    type: typeof FIND_TRAILER_REQUEST_ERROR;
    error: Error;
    query: FindTrailerQueryT;
};

export type RefreshTruckActionT = {
    type: typeof REFRESH_TRUCK_REQUEST;
    query: FindTruckQueryT;
};

export type FindTruckActionT = {
    type: typeof FIND_TRUCK_REQUEST;
    query: FindTruckQueryT;
};

export type FindTruckBeginActionT = {
    type: typeof FIND_TRUCK_REQUEST_BEGIN;
    query: FindTruckQueryT;
};

export type FindTruckSuccessActionT = {
    type: typeof FIND_TRUCK_REQUEST_SUCCESS;
    trucksSuggestItems: AssignmentVehiclesSuggestItemT[];
    query: FindTruckQueryT;
};

export type FindTruckErrorActionT = {
    type: typeof FIND_TRUCK_REQUEST_ERROR;
    error: Error;
    query: FindTruckQueryT;
};

export type PredictSelfCostActionT = {
    type: typeof PREDICT_SELF_COST_REQUEST;
    query: SelfCostPredictionQueryT;
};

export type PredictSelfCostBeginActionT = {
    type: typeof PREDICT_SELF_COST_REQUEST_BEGIN;
    query: SelfCostPredictionQueryT;
};

export type PredictSelfCostSuccessActionT = {
    type: typeof PREDICT_SELF_COST_REQUEST_SUCCESS;
    selfCostPrediction: SelfCostPredictionT | null;
    query: SelfCostPredictionQueryT;
};

export type PredictSelfCostErrorActionT = {
    type: typeof PREDICT_SELF_COST_REQUEST_ERROR;
    error: Error;
    query: SelfCostPredictionQueryT;
};

export type AssignActionT = {
    type: typeof ASSIGNMENT_REQUEST;
    dispatchId: DispatchIdT;
    assignmentContextId: AssignmentContextIdT;
};

export type AssignBeginActionT = {
    type: typeof ASSIGNMENT_REQUEST_BEGIN;
};

export type AssignSuccessActionT = {
    type: typeof ASSIGNMENT_REQUEST_SUCCESS;
};

export type AssignErrorActionT = {
    type: typeof ASSIGNMENT_REQUEST_ERROR;
    error: Error;
};

export type FetchAssetScheduleActionT = {
    type: typeof FETCH_ASSET_SCHEDULE;
    assetId: AssetIdT;
    dispatchId: DispatchIdT;
};

export type FetchAssetScheduleBeginActionT = {
    type: typeof FETCH_ASSET_SCHEDULE_BEGIN;
    assetId: AssetIdT;
};

export type FetchAssetScheduleErrorActionT = {
    type: typeof FETCH_ASSET_SCHEDULE_ERROR;
    assetId: AssetIdT;
    error: Error;
};

export type FetchAssetScheduleSuccessActionT = {
    type: typeof FETCH_ASSET_SCHEDULE_SUCCESS;
    assetId: AssetIdT;
};

export type SetAssetScheduleActionT = {
    type: typeof SET_ASSET_SCHEDULE;
    assetId: AssetIdT;
    events: Array<ScheduleEventT>;
    currentEventId: ScheduleEventIdT | null;
};

export type SetEventLoadRouteActionT = {
    type: typeof SET_EVENT_LOAD_ROUTE;
    assetId: AssetIdT;
    scheduleEventId: ScheduleEventIdT;
    polylines: Array<GooglePolylineT>;
};

export type SetEventAssetTrackActionT = {
    type: typeof SET_EVENT_ASSET_TRACK;
    assetId: AssetIdT;
    scheduleEventId: ScheduleEventIdT;
    track: Array<GeoPointT>;
};

export type SetShowSelectedAssetScheduleActionT = {
    type: typeof SET_SHOW_SELECTED_ASSET_SCHEDULES;
    isShowSelectedAssetSchedules: boolean;
};

export type SetSelectedScheduleEventIdActionT = {
    type: typeof SET_SELECTED_SCHEDULE_EVENT_ID;
    selectedScheduleEventId: ScheduleEventIdT | null;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type InitActionT = {
    type: typeof INIT;
};

export type DispatchAssigmentActionT =
    | InitActionT
    | InitUserSelectionActionT
    | ChangeUserSelectionActionT
    | FetchAvailableVehiclesActionT
    | FetchAvailableVehiclesBeginActionT
    | FetchAvailableVehiclesSuccessActionT
    | FetchAvailableVehiclesErrorActionT
    | FetchAvailableCarriersActionT
    | FetchAvailableCarriersBeginActionT
    | FetchAvailableCarriersSuccessActionT
    | FetchAvailableCarriersErrorActionT
    | RefreshTrailerActionT
    | FindTrailerActionT
    | FindTrailerBeginActionT
    | FindTrailerSuccessActionT
    | FindTrailerErrorActionT
    | RefreshTruckActionT
    | FindTruckActionT
    | FindTruckBeginActionT
    | FindTruckSuccessActionT
    | FindTruckErrorActionT
    | PredictSelfCostActionT
    | PredictSelfCostBeginActionT
    | PredictSelfCostSuccessActionT
    | PredictSelfCostErrorActionT
    | AssignActionT
    | AssignBeginActionT
    | AssignSuccessActionT
    | AssignErrorActionT
    | FetchAssetScheduleActionT
    | FetchAssetScheduleBeginActionT
    | FetchAssetScheduleErrorActionT
    | FetchAssetScheduleSuccessActionT
    | SetAssetScheduleActionT
    | SetEventLoadRouteActionT
    | SetEventAssetTrackActionT
    | SetShowSelectedAssetScheduleActionT
    | SetSelectedScheduleEventIdActionT
    | ResetActionT;
