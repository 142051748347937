import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { formatDateInterval } from 'common/utils/formatters';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { useSelector } from 'react-redux';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const DATE_INTERVAL_FORMAT = 'YYYY-MM-DD';

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const dictTrailerById = useSelector(selectTrailersDictById);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.search', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.address', {
                        value: queryFilters[QueryFiltersKeysEnum.address],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.address],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.created-by-shipper', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByShipperName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdByShipperId, QueryFiltersKeysEnum.createdByShipperName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    const name = queryFilters[QueryFiltersKeysEnum.createdByUserName] || '';

                    return pillIconRendererMap.avatar(name, isCompact);
                },
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.created-by', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByUserName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdByUserId, QueryFiltersKeysEnum.createdByUserName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('spot-requests.list.filters.pills.created-date', {
                        value: formatDateInterval(createdDateFrom, createdDateTo, DATE_INTERVAL_FORMAT),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTrailerTypeId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId];

                    return (
                        <Trans
                            i18nKey="spot-requests.list.filters.pills.trailer"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={selectedTrailerTypeId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerTypeId],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('spot-requests.list.filters.pills.pickup', {
                        value: formatDateInterval(pickupDateFrom, pickupDateTo, DATE_INTERVAL_FORMAT),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('spot-requests.list.filters.pills.drop-off', {
                        value: formatDateInterval(dropOffDateFrom, dropOffDateTo, DATE_INTERVAL_FORMAT),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
        ],
        [t, dictTrailerById],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
