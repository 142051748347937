import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './SpotRequestListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import SpotRequestsTable from 'broker-admin/layouts/SpotRequestsPage/SpotRequestListPage/SpotRequestsTable/SpotRequestsTable';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import QuickFilters, { QuickFiltersOptionT } from 'common/components/QuickFilters/QuickFilters';
import { NotificationLabelThemeEnum } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import { fetchGroupedNotificationCounts } from 'common/store/grouped-notification-counts/actions';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { SpotRequestsQuickFilterEnum } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { SpotRequestT } from 'broker-admin/store/spot-requests/models';
import {
    selectSpotRequestsByIds,
    selectSpotRequestsPages,
    selectSpotRequestsTotal,
} from 'broker-admin/store/spot-requests/selectors';
import { urlFactory as brokerUrlFactory } from 'broker-admin/utils/urls';
import { selectSpotRequestsStats } from 'broker-admin/store/spot-requests-stats/selectors';
import { fetchSpotRequestsPage } from 'broker-admin/store/spot-requests/actions';
import { SpotRequestsSortEnum, SpotRequestStatStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { useCheckOpenedSidebar } from 'shipper/layouts/SideBars/hooks';
import { useQueryParams } from 'use-query-params';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { LinkToT } from 'common/components/Link/Link';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { DEFAULT_BROKER_COMPANY_ID } from 'common/constants';
import { useCheckHasOnlyQueryFilters } from 'common/components/Table/hooks/use-check-has-only-query-filters';
import { useCheckHasMoreQueryFilters } from 'common/components/Table/hooks/use-check-has-more-query-filters';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { spotRequestsPaginationChannel, spotRequestsRefreshChannel } from 'broker-admin/store/spot-requests/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';

const cx = classNames.bind(styles);

type PropsT = {};

const SpotRequestListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const total = useSelector(selectSpotRequestsTotal);
    const spotRequestsPages = useSelector(selectSpotRequestsPages);
    const spotRequestsById = useSelector(selectSpotRequestsByIds);

    const spotRequestsStats = useSelector(selectSpotRequestsStats);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const isOpenedFilters = useCheckOpenedSidebar(BrokerSidebarsTypeEnum.spotRequestsFilter);
    const openLeftSidebar = useOpenLeftSidebar();
    const openSpotRequestsFilter = React.useCallback(() => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.spotRequestsFilter,
        });
    }, [openLeftSidebar]);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.spotRequestsFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.spotRequestsPage]: createPageNumberParam(),
        [QueryKeysEnum.spotRequestsSort]: createSortParams<SpotRequestsSortEnum>({
            value: SpotRequestsSortEnum.expirationTime,
            direction: SortDirectionEnum.DESC,
        }),
    });

    const pageNumber = query[QueryKeysEnum.spotRequestsPage];
    const queryFilters = query[QueryKeysEnum.spotRequestsFilters];
    const selectedSort = query[QueryKeysEnum.spotRequestsSort];

    const page = spotRequestsPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchSpotRequestsPage(pageNumber, query, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchSpotRequestsPage(pageNumber, query, { isForceUpdate: true }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters]);
    useChannelSubscribe(spotRequestsRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchSpotRequestsPage(pageNumber, query));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));

        return (): void => {
            // TODO reset
        };
    }, [page, pageNumber, selectedSort, queryFilters]);

    const spotRequests = React.useMemo(() => {
        return (ids || []).map((id): SpotRequestT => spotRequestsById[id]);
    }, [ids, spotRequestsById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.spotRequestsPage]: pageNumber,
            });
        },
        [changeQuery],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof spotRequestsPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(spotRequestsPaginationChannel, setPageHandler);

    const goToSpotRequestDetails = (spotRequestId: SpotRequestIdT) => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.spotRequestDetails,
            id: spotRequestId,
        });
    };

    const openUserDetails = (userId: UserIdT): void => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId: DEFAULT_BROKER_COMPANY_ID,
            partnerType: PartnerTypeEnum.broker,
            userId,
        });
    };

    const getPartnerLink = (partnerType: PartnerTypeEnum, partnerId: CompanyIdT): LinkToT => {
        return brokerUrlFactory.partnerDetails(partnerType, partnerId);
    };

    const spotRequestsQuickFilterOptions: Array<QuickFiltersOptionT<SpotRequestsQuickFilterEnum>> = [
        {
            id: undefined,
            showPriority: 0,
            hidePriority: 0,
            label: t('spot-requests.list.status-filter.all'),
            testSelector: 'all',
        },
        {
            id: SpotRequestsQuickFilterEnum.open,
            showPriority: 1,
            hidePriority: 1,
            label: t('spot-requests.list.status-filter.open'),
            testSelector: SpotRequestsQuickFilterEnum.open,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.open],
                theme: NotificationLabelThemeEnum.red,
            },
        },
        {
            id: SpotRequestsQuickFilterEnum.accepted,
            showPriority: 2,
            hidePriority: 2,
            label: t('spot-requests.list.status-filter.accepted'),
            testSelector: SpotRequestsQuickFilterEnum.accepted,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.accepted],
                theme: NotificationLabelThemeEnum.orange,
            },
        },
        {
            id: SpotRequestsQuickFilterEnum.newBids,
            showPriority: 3,
            hidePriority: 3,
            label: t('spot-requests.list.status-filter.new-bids'),
            testSelector: SpotRequestsQuickFilterEnum.newBids,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.newBids],
                theme: NotificationLabelThemeEnum.orange,
            },
        },
        {
            id: SpotRequestsQuickFilterEnum.assigned,
            showPriority: 4,
            hidePriority: 4,
            label: t('spot-requests.list.status-filter.assigned'),
            testSelector: SpotRequestsQuickFilterEnum.assigned,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.assigned],
                theme: NotificationLabelThemeEnum.orange,
            },
        },
        {
            id: SpotRequestsQuickFilterEnum.expireSoon,
            showPriority: 5,
            hidePriority: 5,
            label: t('spot-requests.list.status-filter.expire-soon'),
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.expireSoon],
                theme: NotificationLabelThemeEnum.orange,
            },
            testSelector: SpotRequestsQuickFilterEnum.expireSoon,
        },
        {
            id: SpotRequestsQuickFilterEnum.expired,
            showPriority: 6,
            hidePriority: 6,
            label: t('spot-requests.list.status-filter.expired'),
            testSelector: SpotRequestsQuickFilterEnum.expired,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.expired],
                theme: NotificationLabelThemeEnum.orange,
            },
        },
        {
            id: SpotRequestsQuickFilterEnum.canceled,
            showPriority: 7,
            hidePriority: 7,
            label: t('spot-requests.list.status-filter.canceled'),
            testSelector: SpotRequestsQuickFilterEnum.canceled,
            notificationProps: {
                count: spotRequestsStats?.[SpotRequestStatStatusEnum.canceled],
                theme: NotificationLabelThemeEnum.red,
            },
        },
    ];

    const sortOptions: Array<SortDropdownOptionT<SpotRequestsSortEnum>> = React.useMemo(
        () => [
            {
                label: t('spot-requests.list.table.sorts.triggers.expiration-time'),
                value: SpotRequestsSortEnum.expirationTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.placed-time'),
                value: SpotRequestsSortEnum.placedTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.origin-time'),
                value: SpotRequestsSortEnum.originTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.destination-time'),
                value: SpotRequestsSortEnum.destinationTime,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<SpotRequestsSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: SpotRequestsQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.spotRequestsFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: queryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.spotRequestsFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: queryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.spotRequestsFilters] || {};

        const queryFilters = {
            [QueryFiltersKeysEnum.quickFilter]: prevQueryFilters[QueryFiltersKeysEnum.quickFilter],
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.address]: undefined,
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const hasOnlyQuickFilter = useCheckHasOnlyQueryFilters(queryFilters, [QueryFiltersKeysEnum.quickFilter]);
    const hasMoreQuickFilter = useCheckHasMoreQueryFilters(queryFilters, [QueryFiltersKeysEnum.quickFilter]);

    const renderTableMessage = () => {
        if (spotRequests.length || !(requestStatus?.ok || requestStatus?.error)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        if (requestStatus?.ok && hasOnlyQuickFilter) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('spot-requests.list.table.messages.empty-quick-filters.title')}
                    testSelector="empty-quick-filter"
                    isShowAction={false}
                />
            );
        }

        if (requestStatus?.ok && hasMoreQuickFilter) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('spot-requests.list.table.messages.not-found.title')}
                    description={t('spot-requests.list.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('spot-requests.list.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="not-found"
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('spot-requests.list.table.messages.empty.title')}
                testSelector="empty"
                isShowAction={false}
            />
        );
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.spot')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('spot-requests.list.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <QuickFilters
                            options={spotRequestsQuickFilterOptions}
                            selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                            onSelect={handleSetStatusFilter}
                            moreStatusesLabel={t('spot-requests.list.status-filter.more-statuses')}
                        />
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('spot-requests.list.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openSpotRequestsFilter}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('spot-requests.list.search-placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                    />
                    {renderTableMessage()}
                    <SpotRequestsTable
                        spotRequests={spotRequests}
                        className={cx('table')}
                        goToSpotRequestDetails={goToSpotRequestDetails}
                        isLoading={requestStatus?.loading}
                        getPartnerLink={getPartnerLink}
                        openUserDetails={openUserDetails}
                    />
                </ListPageLayout>
                <StickyFooter>
                    <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
                </StickyFooter>
                <SideBars />
            </ContentMargins>
        </ScrollableContent>
    );
});

export default SpotRequestListPage;
