export enum FieldsEnum {
    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',

    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',

    shipperId = 'shipperId',
    shipperName = 'shipperName',

    carrierId = 'carrierId',
    carrierName = 'carrierName',

    dictTrailerId = 'dictTrailerId',

    address = 'address',

    createDates = 'createDates',
    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',
}

export type FormValuesT = {
    [FieldsEnum.dispatcherId]: string | null;
    [FieldsEnum.dispatcherName]: string | null;

    [FieldsEnum.keyAccountManagerId]: string | null;
    [FieldsEnum.keyAccountManagerName]: string | null;

    [FieldsEnum.shipperId]: string | null;
    [FieldsEnum.shipperName]: string | null;

    [FieldsEnum.carrierId]: string | null;
    [FieldsEnum.carrierName]: string | null;

    [FieldsEnum.dictTrailerId]: string | null;

    [FieldsEnum.address]: string | null;

    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;
};
