import { SpotRequestsQuickFilterEnum } from './constants';

export enum QueryKeysEnum {
    spotRequestsPage = 'spot-requests-page',
    spotRequestsSort = 'spot-requests-sort',
    spotRequestsFilters = 'spot-requests-filters',
}

export enum QueryFiltersKeysEnum {
    quickFilter = 'quick-filter',
    searchText = 'search-text',
    createdByShipperId = 'created-by-shipper-id',
    createdByShipperName = 'created-by-shipper-name',
    dictTrailerTypeId = 'dict-trailer-type-id',
    address = 'address',
    pickupDateFrom = 'pickup-docking-hours-from',
    pickupDateTo = 'pickup-docking-hours-to',
    dropOffDateFrom = 'drop-off-docking-hours-from',
    dropOffDateTo = 'drop-off-docking-hours-to',
    createdDateFrom = 'created-date-from',
    createdDateTo = 'created-date-to',
    createdByUserId = 'created-by-user-id',
    createdByUserName = 'created-by-user-name',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.quickFilter]: SpotRequestsQuickFilterEnum;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByShipperId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByShipperName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dictTrailerTypeId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.address]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByUserId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByUserName]: QueryParam.StringT;
}>;
