import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './DispatchDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import { PartnerTypeEnum, ShipmentStatusEnum } from 'common/utils/api/models';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { urlFactory } from '../../../../utils/urls';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import RouteIcon from 'common/icons/RouteIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import ShipperIcon from 'common/icons/ShipperIcon';
import { convertToKm } from 'common/utils/distance';
import TourPapersCard from 'common/components/TourPapersCard/TourPapersCard';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import InvoiceBinaryStatusPill from 'common/components/status-pill/InvoiceBinaryStatusPill/InvoiceBinaryStatusPill';
import ShipperPriceOfferInfoTable, {
    ShipperPriceOfferInfoTablePropsT,
} from 'common/components/info-tables/ShipperPriceOfferInfoTable/ShipperPriceOfferInfoTable';
import CarrierCostPriceInfoTable from 'broker-admin/components/info-tables/CarrierCostPriceInfoTable/CarrierCostPriceInfoTable';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import { useMoneyDiff } from 'common/utils/hooks/useMoneyDiff';
import isNumber from 'lodash/isNumber';
import Invoice2Icon from 'common/icons/Invoice2Icon';
import PaperIcon from 'common/icons/PaperIcon';
import LinkFormatter from 'design-system/components/InfoTable/formatters/LinkFormatter/LinkFormatter';
import ShipperContractStatusPill from 'common/components/status-pill/ShipperContractStatusPill/ShipperContractStatusPill';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';
import ShipperContractLaneOrderCounterPill from 'common/components/pills/ShipperContractLaneOrderCounterPill/ShipperContractLaneOrderCounterPill';
import EntriesTabs, { EntryCountThemeEnum, EntryTabT } from 'common/components/tabs/EntriesTabs/EntriesTabs';
import { useEntriesTabs } from 'common/components/tabs/EntriesTabs/hooks';
import TourDetailsCard from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/TourDetailsCard/TourDetailsCard';
import ShipmentDetailsCard from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/ShipmentDetailsCard/ShipmentDetailsCard';
import TransportOrderDetailsCard from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/TransportOrderDetailsCard/TransportOrderDetailsCard';
import isNil from 'lodash/isNil';
import AsyncTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/AsyncTrailerTypeFormatter/AsyncTrailerTypeFormatter';
import keyBy from 'lodash/keyBy';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';
import { useDispatchPayloadWaypoints } from 'broker-admin/utils/hooks/useDispatchPayloadWaypoints';

type PropsT = {
    isDisableAnyActions: boolean;
    dispatchDetails: DispatchDetailsT | null | undefined;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
    onStartAssignment: () => void;
    onStartSendToSpot: () => void;
    onStartEditTimeWindows: () => void;
};

const cx = classNames.bind(styles);

enum TabEnum {
    tours = 'tours',
    shipments = 'shipments',
    transportOrders = 'transport-orders',
}

const DispatchDetails: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const {
        dispatchDetails,
        isDisableAnyActions,
        onOpenUserDetails,
        onStartEditTimeWindows,
        onStartAssignment,
        onStartSendToSpot,
    } = props;

    const waypointById = useMemo(() => {
        return keyBy(dispatchDetails?.tours?.[0]?.waypoints, 'id');
    }, [dispatchDetails]);

    const firstTour = dispatchDetails?.tours?.[0] || null;
    const firstTransportationOrder = firstTour?.transportationOrders?.[0] || null;

    const priceOffer = dispatchDetails?.priceOffer || null;

    const mileageDetails: Array<InfoTableRowT | null> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.columns.total-mileage'),
            value: (
                <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(priceOffer?.distance)} />
            ),
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            testSelector: 'total-mileage',
        },
        !'TODO'
            ? {
                  icon: null,
                  name: t('dispatch-details.columns.payload-mileage'),
                  value: (
                      <UnitTypeCount
                          type={UnitTypeEnum.kilometersAbbreviation}
                          count={convertToKm(priceOffer?.distance)}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: 'payload-mileage',
              }
            : null,
        !'TODO'
            ? {
                  icon: null,
                  name: t('dispatch-details.columns.deadhead-mileage'),
                  value: (
                      <UnitTypeCount
                          type={UnitTypeEnum.kilometersAbbreviation}
                          count={convertToKm(priceOffer?.distance)}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: 'deadhead-mileage',
              }
            : null,
    ];

    const moveDetails: Array<InfoTableRowT> = [
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.columns.trailer-type'),
            value: <AsyncTrailerTypeFormatter trailerDictId={dispatchDetails?.trailerInfo?.id} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'trailer-type',
        },
        {
            icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.columns.emission-class'),
            value: <EmissionClassFormatter emissionClass={dispatchDetails?.emissionClass} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'emissions-class',
        },
    ];

    const commonPriceDetails = useCommonPriceDetails();

    const carrierTotalCostDiff = useMoneyDiff(
        firstTransportationOrder?.carrierCost?.totalCost,
        dispatchDetails?.priceOffer?.totalPrice,
    );

    const carrierTotalCost = firstTransportationOrder?.carrierCost?.totalCost;

    const carrierCostDetails: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: t('dispatch-details.columns.carrier-cost'),
            price: firstTransportationOrder?.carrierCost?.totalCost,
            priceDiff: carrierTotalCostDiff,
            placeholder: t('dispatch-details.columns-empty-placeholders.assets-not-assigned'),
            list: isNumber(carrierTotalCost)
                ? [
                      {
                          ...commonPriceDetails.lineHaul,
                          price: firstTransportationOrder?.carrierCost?.lineHaul,
                      },
                      {
                          ...commonPriceDetails.fuelCost,
                          price: firstTransportationOrder?.carrierCost?.fuelCost,
                      },
                      {
                          ...commonPriceDetails.roadTaxes,
                          price: firstTransportationOrder?.carrierCost?.tollCost,
                      },
                      {
                          iconNode: null,
                          title: t('common:order-details.columns.other-costs'),
                          price: firstTransportationOrder?.carrierCost?.otherCosts,
                      },
                  ]
                : [],
        },
    ];

    const shipperLink = urlFactory.partnerDetails(PartnerTypeEnum.shipper, dispatchDetails?.order?.shipper?.id);

    const creationDetails: Array<InfoTableRowT | null> = [
        {
            icon: <ShipperIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.columns.shipper'),
            value: <LinkFormatter to={shipperLink}>{dispatchDetails?.order?.shipper?.name}</LinkFormatter>,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'shipper',
        },
        {
            icon: null,
            name: t('dispatch-details.columns.order-id'),
            value: dispatchDetails?.order?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            rightNode: <OrderStatusPill isSymmetrical status={dispatchDetails?.order?.status} />,
            isBoldValue: true,
            testSelector: 'order-id',
        },
        {
            icon: null,
            name: t('dispatch-details.columns.order-created-on'),
            value: <DateFormatter date={dispatchDetails?.order?.createdDate} format="DD MMM YYYY" />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'order-created-on',
        },
        {
            icon: null,
            name: t('dispatch-details.columns.order-created-by'),
            value: dispatchDetails?.order?.createdBy ? (
                <LinkFormatter
                    onClick={() => {
                        onOpenUserDetails({
                            partnerType: PartnerTypeEnum.shipper,
                            partnerId: dispatchDetails?.order?.shipper?.id || '',
                            userId: dispatchDetails?.order?.createdBy?.id || null,
                        });
                    }}
                >
                    {dispatchDetails?.order?.createdBy?.fullName}
                </LinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'order-created-by',
        },
        {
            icon: (
                <Invoice2Icon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.columns.invoice'),
            value: dispatchDetails?.order?.invoiceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            rightNode: <InvoiceBinaryStatusPill isSymmetrical isPaid={!!dispatchDetails?.order?.paid} />,
            testSelector: 'invoice',
        },
        dispatchDetails?.order?.shipperContract
            ? {
                  icon: (
                      <PaperIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={StyleGuideColorsEnum.gray}
                          fillColor={StyleGuideColorsEnum.light}
                      />
                  ),
                  name: t('dispatch-details.columns.shipper-contract'),
                  value: (
                      <LinkFormatter
                          to={urlFactory.partnerShipperContactDetails({
                              partnerId: dispatchDetails?.order?.shipper?.id || '-',
                              partnerType: PartnerTypeEnum.shipper,
                              shipperContractId: dispatchDetails?.order?.shipperContract?.id || '-',
                          })}
                      >
                          {dispatchDetails?.order?.shipperContract?.name}
                      </LinkFormatter>
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  rightNode: dispatchDetails?.order?.shipperContract ? (
                      <ShipperContractStatusPill
                          isSymmetrical
                          status={dispatchDetails?.order?.shipperContract?.status}
                      />
                  ) : null,
                  isBoldValue: true,
                  testSelector: 'shipper-contract',
              }
            : null,
        dispatchDetails?.order?.shipperContractLane
            ? {
                  icon: (
                      <PaperIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={StyleGuideColorsEnum.gray}
                          fillColor={StyleGuideColorsEnum.light}
                      />
                  ),
                  name: t('dispatch-details.columns.shipper-contract-lane'),
                  value: (
                      <LinkFormatter
                          to={urlFactory.partnerShipperContractLane({
                              partnerId: dispatchDetails?.order?.shipper?.id || '-',
                              partnerType: PartnerTypeEnum.shipper,
                              contractId: dispatchDetails?.order?.shipperContract?.id || '-',
                              laneId: dispatchDetails?.order?.shipperContractLane?.id || '-',
                          })}
                      >
                          {dispatchDetails?.order?.shipperContractLane?.tztLaneId}
                      </LinkFormatter>
                  ),
                  rightNode: (
                      <>
                          <ShipperContractLaneOrderCounterPill
                              ordersLeft={dispatchDetails?.order?.shipperContractLane?.ordersLeft}
                              maxNumberOfOrders={dispatchDetails?.order?.shipperContractLane?.maxNumberOfOrders}
                              status={dispatchDetails?.order?.shipperContract?.status}
                              isSymmetrical
                          />
                          <ShipperContractLaneStatusPill
                              isSymmetrical
                              status={dispatchDetails?.order?.shipperContractLane?.status}
                          />
                      </>
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'shipper-contract-lane',
              }
            : null,
    ];

    const { activeEntriesTabType, setActiveEntriesTabType } = useEntriesTabs<TabEnum>(TabEnum.tours);

    const tabs = React.useMemo((): Array<EntryTabT<TabEnum> | null> => {
        const transportOrderCount = dispatchDetails?.tours?.flatMap((tour) => {
            return tour?.transportationOrders || [];
        })?.length;

        const tourCount = dispatchDetails?.tours?.length || 0;

        const shipmentCount = dispatchDetails?.shipments?.length || 0;

        return [
            tourCount
                ? {
                      type: TabEnum.tours,
                      label: t('dispatch-details.tab-titles.tours'),
                      counter: {
                          value: tourCount,
                          theme: EntryCountThemeEnum.warning,
                      },
                  }
                : null,
            shipmentCount
                ? {
                      type: TabEnum.shipments,
                      label: t('dispatch-details.tab-titles.shipments'),
                      counter: {
                          value: shipmentCount,
                          theme: EntryCountThemeEnum.normal,
                      },
                  }
                : null,
            transportOrderCount
                ? {
                      type: TabEnum.transportOrders,
                      label: t('dispatch-details.tab-titles.transport-orders'),
                      counter: {
                          value: transportOrderCount,
                          theme: EntryCountThemeEnum.normal,
                      },
                  }
                : null,
        ];
    }, [t, dispatchDetails]);

    const additionalServices = useMemo((): ShipperPriceOfferInfoTablePropsT['additionalServices'] => {
        if (!dispatchDetails?.additionalServices) {
            return [];
        }

        return dispatchDetails.additionalServices.reduce<
            NonNullable<ShipperPriceOfferInfoTablePropsT['additionalServices']>
        >((acc, additionalService) => {
            if (additionalService.type && !isNil(additionalService.id) && !isNil(additionalService.cost)) {
                acc.push({
                    enum: additionalService.type,
                    cost: additionalService.cost,
                    id: additionalService.id,
                });
            }

            return acc;
        }, []);
    }, [dispatchDetails?.additionalServices]);

    const { firstPayloadWaypoint, lastPayloadWaypoint } = useDispatchPayloadWaypoints(
        dispatchDetails?.tours?.[0]?.waypoints,
    );

    const checkIsLastNotCanceledShipment = (shipmentId: ShipmentIdT): boolean => {
        return (
            dispatchDetails?.shipments?.every((shipment) => {
                if (shipment.id === shipmentId && shipment.status !== ShipmentStatusEnum.canceled) {
                    return true;
                }

                return shipment.status === ShipmentStatusEnum.canceled;
            }) || false
        );
    };

    if (!dispatchDetails) {
        return null;
    }

    return (
        <>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstPayloadWaypoint?.address}
                pickupDockingHoursFrom={firstPayloadWaypoint?.dateTimeFrom}
                pickupDockingHoursTo={firstPayloadWaypoint?.dateTimeTo}
                destination={lastPayloadWaypoint?.address}
                dropOffDockingHoursFrom={lastPayloadWaypoint?.dateTimeFrom}
                dropOffDockingHoursTo={lastPayloadWaypoint?.dateTimeTo}
            />
            <ExcludedCountries
                className={cx('excluded-countries')}
                titleNode={t('dispatch-details.excluded-countries.title')}
                countryCodes={dispatchDetails?.prohibitedCountries || []}
                tooltipNode={t('dispatch-details.excluded-countries.tooltip')}
            />
            {!'TODO' && (
                <ExcludedCountries
                    className={cx('excluded-countries')}
                    titleNode={t('dispatch-details.broker-excluded-countries.title')}
                    countryCodes={dispatchDetails?.prohibitedCountries || []}
                    tooltipNode={t('dispatch-details.broker-excluded-countries.tooltip')}
                />
            )}
            {firstTour?.runningOutOfTimeToAssignAsset && (
                <Alert
                    icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('dispatch-details.alerts.running-out-of-time-assign-assets')}
                </Alert>
            )}
            {firstTour?.runOutOfTimeToAssignAsset && (
                <Alert
                    icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('dispatch-details.alerts.run-out-of-time-assign-assets')}
                </Alert>
            )}
            {firstTour?.runningOutOfTimeToAssignDriver && (
                <Alert
                    icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('dispatch-details.alerts.running-out-of-time-assign-driver')}
                </Alert>
            )}
            {firstTour?.runOutOfTimeToAssignDriver && (
                <Alert
                    icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('dispatch-details.alerts.run-out-of-time-assign-driver')}
                </Alert>
            )}
            {firstTour?.delaying && (
                <Alert
                    icon={<StopWarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('dispatch-details.alerts.delaying')}
                </Alert>
            )}
            <InfoTable
                shouldRenderIcons
                className={cx('table', 'table--creation-details')}
                rows={creationDetails}
                testSelector="creation-details"
                isCollapsable
                isInitOpen
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table', 'table--mileage')}
                rows={mileageDetails}
                hasSymmetricalOpenedMargins
                testSelector="mileage-details"
                isCollapsable
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table', 'table--move-details')}
                rows={moveDetails}
                testSelector="move-details"
            />
            <ShipperPriceOfferInfoTable
                className={cx('table', 'table--shipper-price')}
                totalPriceLabel={t('dispatch-details.columns.shipper-price')}
                hasContract={!!dispatchDetails?.order?.shipperContract}
                totalPrice={dispatchDetails?.priceOffer?.totalPrice}
                lineHaulCost={dispatchDetails?.priceOffer?.lineHaul}
                tollCost={dispatchDetails?.priceOffer?.tollCost}
                tollByRoadType={dispatchDetails?.priceOffer?.tollByRoadType}
                tranziitServiceFee={dispatchDetails?.priceOffer?.serviceFee}
                urgentOverprice={dispatchDetails?.priceOffer?.urgentOverprice}
                layoverCost={dispatchDetails?.priceOffer?.layoverCost}
                fuelCost={dispatchDetails?.priceOffer?.fuelCost}
                greenCost={dispatchDetails?.priceOffer?.greenOverprice}
                additionalServicesCost={dispatchDetails?.priceOffer?.additionalServicesCost}
                additionalServices={additionalServices}
                shouldAlwaysRenderExpandTrigger
            />
            <div className={cx('separator')} />
            <CarrierCostPriceInfoTable
                className={cx('table')}
                totalPrice={dispatchDetails?.carrierEstimatedCost?.totalCost}
                totalPriceLabel={t('dispatch-details.columns.carrier-estimated-cost')}
                lineHaulCost={dispatchDetails?.carrierEstimatedCost?.lineHaul}
                tollCost={dispatchDetails?.carrierEstimatedCost?.tollCost}
                layoverCost={dispatchDetails?.carrierEstimatedCost?.layoverCost}
                fuelCost={dispatchDetails?.carrierEstimatedCost?.fuelCost}
                teamDriveCost={dispatchDetails?.carrierEstimatedCost?.teamDriveCost}
                otherCosts={dispatchDetails?.carrierEstimatedCost?.otherCosts}
                testSelector="estimated-carrier-cost"
                shouldAlwaysRenderExpandTrigger
            />
            <div className={cx('separator')} />
            <PriceDetails
                className={cx('table', 'table--carrier-cost')}
                list={carrierCostDetails}
                shouldAlwaysRenderExpandTrigger
            />
            <EntriesTabs
                className={cx('tabs')}
                activeEntriesTabType={activeEntriesTabType}
                setActiveEntriesTabType={setActiveEntriesTabType}
                tabs={tabs}
            />
            {activeEntriesTabType === TabEnum.tours && (
                <>
                    {dispatchDetails?.tours?.map((tour, tourIndex) => {
                        return (
                            <TourDetailsCard
                                key={tourIndex}
                                tour={tour}
                                tourNumber={tourIndex + 1}
                                dispatchDetails={dispatchDetails}
                                className={cx('entry-card')}
                                isDisableAnyActions={isDisableAnyActions}
                                onStartAssignment={onStartAssignment}
                                onStartSendToSpot={onStartSendToSpot}
                                onStartEditTimeWindows={onStartEditTimeWindows}
                            />
                        );
                    })}
                </>
            )}
            {activeEntriesTabType === TabEnum.transportOrders && (
                <>
                    {dispatchDetails?.tours?.map((tour, tourIndex) => {
                        return tour?.transportationOrders?.map((transportationOrder, transportationOrderIndex) => {
                            return (
                                <TransportOrderDetailsCard
                                    key={`${tourIndex}-${transportationOrderIndex}`}
                                    transportOrderDetails={transportationOrder}
                                    tour={tour}
                                    transportOrderNumber={transportationOrder.number}
                                    dispatchDetails={dispatchDetails}
                                    className={cx('entry-card')}
                                />
                            );
                        });
                    })}
                </>
            )}
            {activeEntriesTabType === TabEnum.shipments && (
                <>
                    {dispatchDetails?.shipments?.map((shipment, shipmentIndex) => {
                        return (
                            <ShipmentDetailsCard
                                key={shipmentIndex}
                                isCurrentLastNotCanceledShipment={checkIsLastNotCanceledShipment(shipment.id)}
                                isReeferTrailer={!!dispatchDetails?.trailerInfo?.reefer}
                                shipment={shipment}
                                waypointById={waypointById}
                                shipmentNumber={shipmentIndex + 1}
                                dispatchId={dispatchDetails?.id || null}
                                className={cx('entry-card')}
                            />
                        );
                    })}
                </>
            )}
            <TourPapersCard
                className={cx('papers-card')}
                tourId={firstTour?.id || null}
                isBroker
                title={t('common:order-details.papers')}
            />
        </>
    );
});

export default DispatchDetails;
