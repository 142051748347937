import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { QueryFiltersT } from 'common/models';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { useDispatch } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import CarrierDropdown from 'broker-admin/components/dropdowns/CarrierDropdown/CarrierDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import ShipperDropdown from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperDropdown';
import DispatcherDropdown from 'broker-admin/components/dropdowns/DispatcherDropdown/DispatcherDropdown';
import KeyAccountManagerDropdown from 'broker-admin/components/dropdowns/KeyAccountManagerDropdown/KeyAccountManagerDropdown';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';
import SelectedFilters from '../SelectedFilters/SelectedFilters';
import { mergeQueryFilters } from 'common/utils/query';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose, setQueryFilters, queryFilters } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const newQueryFilters = getQueryFilters(values);
            const mergedQueryFilters = mergeQueryFilters(initialQueryFilters, newQueryFilters);
            setQueryFilters(mergedQueryFilters);

            formikHelpers.setTouched({});
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const hasAddressValue = !!formik.values[FieldsEnum.address];

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('dispatches.list.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dispatcher')}
                        label={t('dispatches.list.filters.fields.dispatcher')}
                        name={FieldsEnum.dispatcherId}
                        error={formik.errors[FieldsEnum.dispatcherId]}
                        meta={formik.getFieldMeta(FieldsEnum.dispatcherId)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DispatcherDropdown
                                selectedValue={formik.values[FieldsEnum.dispatcherId]}
                                initialLabel={initialValues[FieldsEnum.dispatcherName]}
                                placeholder={t('dispatches.list.filters.placeholders.dispatcher')}
                                onSelect={(memberId, memberName) => {
                                    formik.setFieldValue(FieldsEnum.dispatcherId, memberId);
                                    formik.setFieldValue(FieldsEnum.dispatcherName, memberName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                onReset={() => {
                                    formik.setFieldValue(FieldsEnum.dispatcherId, null);
                                    formik.setFieldValue(FieldsEnum.dispatcherName, null);
                                }}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--key-account-manager')}
                        label={t('dispatches.list.filters.fields.key-account-manager')}
                        name={FieldsEnum.keyAccountManagerId}
                        error={formik.errors[FieldsEnum.keyAccountManagerId]}
                        meta={formik.getFieldMeta(FieldsEnum.keyAccountManagerId)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <KeyAccountManagerDropdown
                                selectedValue={formik.values[FieldsEnum.keyAccountManagerId]}
                                initialLabel={initialValues[FieldsEnum.keyAccountManagerName]}
                                placeholder={t('dispatches.list.filters.placeholders.key-account-manager')}
                                onSelect={(memberId, memberName) => {
                                    formik.setFieldValue(FieldsEnum.keyAccountManagerId, memberId);
                                    formik.setFieldValue(FieldsEnum.keyAccountManagerName, memberName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                onReset={() => {
                                    formik.setFieldValue(FieldsEnum.keyAccountManagerId, null);
                                    formik.setFieldValue(FieldsEnum.keyAccountManagerName, null);
                                }}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--shipper')}
                        name={FieldsEnum.shipperId}
                        error={formik.errors[FieldsEnum.shipperId]}
                        meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                        label={t('dispatches.list.filters.fields.shipper')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ShipperDropdown
                                value={formik.values[FieldsEnum.shipperId]}
                                initialValue={initialValues[FieldsEnum.shipperId]}
                                initialLabel={initialValues[FieldsEnum.shipperName] || ''}
                                hasClearControl
                                onSelect={(shipperId, shipperName) => {
                                    formik.setFieldValue(FieldsEnum.shipperId, shipperId);
                                    formik.setFieldValue(FieldsEnum.shipperName, shipperName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                placeholder={t('dispatches.list.filters.placeholders.shipper')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--carrier')}
                        name={FieldsEnum.carrierId}
                        error={formik.errors[FieldsEnum.carrierId]}
                        meta={formik.getFieldMeta(FieldsEnum.carrierId)}
                        label={t('dispatches.list.filters.fields.carrier')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CarrierDropdown
                                value={formik.values[FieldsEnum.carrierId]}
                                initialValue={initialValues[FieldsEnum.carrierId]}
                                initialLabel={initialValues[FieldsEnum.carrierName] || ''}
                                hasClearControl
                                onSelect={(carrierId, carrierName) => {
                                    formik.setFieldValue(FieldsEnum.carrierId, carrierId);
                                    formik.setFieldValue(FieldsEnum.carrierName, carrierName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                placeholder={t('dispatches.list.filters.placeholders.carrier')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--trailer-type')}
                        name={FieldsEnum.dictTrailerId}
                        error={formik.errors[FieldsEnum.dictTrailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                        label={t('dispatches.list.filters.fields.trailer-type')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DictTrailerDropdown
                                placeholder={t('dispatches.list.filters.placeholders.trailer-type')}
                                value={formik.values[FieldsEnum.dictTrailerId]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('dispatches.list.filters.fields.created-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('dispatches.list.filters.placeholders.created-dates')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FormikField
                    className={cx('field', 'field--address')}
                    name={FieldsEnum.address}
                    error={formik.errors[FieldsEnum.address]}
                    meta={formik.getFieldMeta(FieldsEnum.address)}
                    label={t('dispatches.list.filters.fields.address')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipPosition={TooltipPositionEnum.topLeft}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('dispatches.list.filters.tooltip.address')}
                        </TooltipContent>
                    }
                >
                    {(props) => (
                        <Input
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            name={FieldsEnum.address}
                            value={formik.values[FieldsEnum.address] || ''}
                            placeholder={t('dispatches.list.filters.placeholders.address')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.pickupDates}
                        error={formik.errors[FieldsEnum.pickupDates]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                        label={t('dispatches.list.filters.fields.pickup-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.pickupDates]}
                                placeholder={t('dispatches.list.filters.placeholders.pickup-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.dropoffDates}
                        error={formik.errors[FieldsEnum.dropoffDates]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                        label={t('dispatches.list.filters.fields.drop-off-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.dropoffDates]}
                                placeholder={t('dispatches.list.filters.placeholders.drop-off-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('dispatches.list.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;
