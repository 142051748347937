import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RateMatrix.scss';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'common/utils/hooks/useQuery';
import { QueryKeysEnum } from 'common/constants';
import history from 'common/utils/history';
import SearchForm from './SearchForm/SearchForm';
import { CountryCodeT } from 'common/store/countries-dict/models';
import Pagination from 'common/components/Table/Pagination/Pagination';
import RatesTable from './RatesTable/RatesTable';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import {
    selectRatesByIds,
    selectRatesPages,
    selectRatesTotal,
    selectUpdateRatesRequest,
} from 'broker-admin/store/price-settings/rates/selectors';
import { fetchRatesPage, updateRates, uploadCSVFile } from 'broker-admin/store/price-settings/rates/actions';
import { ChangeCostT, RateT } from 'broker-admin/store/price-settings/rates/models';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { useTranslation } from 'react-i18next';
import ChangeRatesForm from './ChangeRatesForm/ChangeRatesForm';
import { UpdateRateStrategyEnum } from 'common/utils/api/models';
import FiltersPill, { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import parsePageNumber from 'common/utils/parse-page-number';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import Modal from 'common/components/Modal/Modal';
import ImportCsv from './ImportCsv/ImportCsv';
import { formatQuery } from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import TableError from 'common/components/Table/TableError/TableError';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import { pickFile } from 'common/utils/pick-file';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CountriesFilterPill from 'common/components/filter-pills/CountriesFilterPill/CountriesFilterPill';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { ratesPaginationChannel, ratesRefreshChannel } from 'broker-admin/store/price-settings/rates/channels';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { InferChannelEventT } from 'common/utils/view-event-channel';

const cx = classNames.bind(styles);

type QueryT = {
    [QueryKeysEnum.ratesFromCountryCodesSearch]?: Array<CountryCodeT>;
    [QueryKeysEnum.ratesToCountryCodesSearch]?: Array<CountryCodeT>;
    [QueryKeysEnum.ratesPage]?: string;
};

const EMPTY_COUNTRY_CODES: Array<CountryCodeT> = [];

type PropsT = {};

const RateMatrix: React.FC<PropsT> = () => {
    const { t } = useTranslation();

    const { partnerId, partnerType } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in price settings rules, empty userId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const dispatch = useDispatch();

    const total = useSelector(selectRatesTotal);
    const ratesPages = useSelector(selectRatesPages);
    const rateById = useSelector(selectRatesByIds);

    const updateRatesRequest = useSelector(selectUpdateRatesRequest);

    const [selectedRatesSet, setSelectedRatesSet] = React.useState<Set<RateT>>(new Set());

    const query = useQuery<QueryT>();
    const fromCountryCodes = query[QueryKeysEnum.ratesFromCountryCodesSearch] || EMPTY_COUNTRY_CODES;
    const toCountryCodes = query[QueryKeysEnum.ratesToCountryCodesSearch] || EMPTY_COUNTRY_CODES;

    const handleChangeFilters = (fromCountryCodes: CountryCodeT[], toCountryCodes: CountryCodeT[]) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.ratesFromCountryCodesSearch]: fromCountryCodes,
                [QueryKeysEnum.ratesToCountryCodesSearch]: toCountryCodes,
            }),
        });
    };

    const handleOmitFilters = (omitFromCountryCodes: CountryCodeT[], omitToCountryCodes: CountryCodeT[]) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.ratesFromCountryCodesSearch]: difference(fromCountryCodes, omitFromCountryCodes),
                [QueryKeysEnum.ratesToCountryCodesSearch]: difference(toCountryCodes, omitToCountryCodes),
            }),
        });
    };

    const pageNumber = parsePageNumber(query[QueryKeysEnum.ratesPage]);
    const page = ratesPages[pageNumber];
    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        setSelectedRatesSet(new Set());
    }, [ids]);

    React.useEffect(() => {
        dispatch(
            fetchRatesPage(pageNumber, {
                countriesFrom: fromCountryCodes,
                countriesTo: toCountryCodes,
            }),
        );

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, fromCountryCodes, toCountryCodes]);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(
            fetchRatesPage(
                pageNumber,
                {
                    countriesFrom: fromCountryCodes,
                    countriesTo: toCountryCodes,
                },
                { isForceUpdate: true },
            ),
        );
    }, [pageNumber, fromCountryCodes, toCountryCodes]);
    useChannelSubscribe(ratesRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(
            fetchRatesPage(
                pageNumber,
                {
                    countriesFrom: fromCountryCodes,
                    countriesTo: toCountryCodes,
                },
                { isForceUpdate: false },
            ),
        );
    }, [pageNumber, fromCountryCodes, toCountryCodes]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            history.push({
                search: formatQuery({
                    ...query,
                    [QueryKeysEnum.ratesPage]: pageNumber,
                }),
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof ratesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(ratesPaginationChannel, setPageHandler);

    const rates = React.useMemo(() => {
        return (ids || []).map((id): RateT => rateById[id]);
    }, [ids, rateById]);

    const hasQuery = !isEmpty(fromCountryCodes) && !isEmpty(toCountryCodes);

    const handleChangeRates = (changeCost: ChangeCostT) => {
        const ids = [...selectedRatesSet].map((rate) => rate.id);

        if (ids.length && changeCost.value) {
            dispatch(updateRates(ids, changeCost.value, changeCost.strategy));
        }

        setSelectedRatesSet(new Set());
    };

    const handleUpdateRate = (id: RateT['id'], value: number) => {
        dispatch(updateRates([id], value, UpdateRateStrategyEnum.exactValue));

        setSelectedRatesSet(new Set());
    };

    const [isShowCSVImportModal, setShowCSVImportModal] = React.useState<boolean>(false);

    const uploadFile = () => {
        pickFile('.csv', (file) => {
            dispatch(uploadCSVFile(file));

            setShowCSVImportModal(true);
        });
    };

    return (
        <ContentMargins>
            <ListPageLayout>
                <ListPageHeaderLayout
                    withTopPadding
                    leftToolsNode={
                        <SearchForm
                            className={cx('control')}
                            initialFromCountryCodes={fromCountryCodes}
                            initialToCountryCodes={toCountryCodes}
                            onChange={handleChangeFilters}
                        />
                    }
                    rightToolsNode={
                        <>
                            <Button
                                theme={ButtonThemeEnum.primary}
                                className={cx('control', 'control--upload-csv')}
                                onClick={uploadFile}
                            >
                                {t('price-settings.rate-matrix.import-csv.trigger')}
                            </Button>
                            {!!selectedRatesSet.size && (
                                <ChangeRatesForm
                                    onSubmit={handleChangeRates}
                                    className={cx('control', 'control--change-rates-form')}
                                />
                            )}
                        </>
                    }
                    filterTagsNode={
                        <>
                            {!!fromCountryCodes?.length && (
                                <>
                                    <FiltersPill
                                        key="from-clear-all"
                                        className={cx('filters-pills__item')}
                                        label={t('price-settings.rate-matrix.clear-from-country-codes')}
                                        withCloser={false}
                                        isCompact
                                        theme={FilterPillThemeEnum.charcoal}
                                        onClick={() => handleChangeFilters([], toCountryCodes)}
                                    />
                                    {fromCountryCodes.map((code) => (
                                        <FiltersPill
                                            key={`from-${code}`}
                                            className={cx('filters-pills__item')}
                                            label={<CountriesFilterPill countryCodes={[code]} />}
                                            withCloser
                                            isCompact
                                            theme={FilterPillThemeEnum.charcoal}
                                            onClick={() => handleOmitFilters([code], [])}
                                        />
                                    ))}
                                </>
                            )}
                            {!!toCountryCodes?.length && (
                                <>
                                    <FiltersPill
                                        key="to-clear-all"
                                        className={cx('filters-pills__item')}
                                        label={t('price-settings.rate-matrix.clear-to-country-codes')}
                                        withCloser={false}
                                        isCompact
                                        theme={FilterPillThemeEnum.brandDark}
                                        onClick={() => handleChangeFilters(fromCountryCodes, [])}
                                    />
                                    {toCountryCodes.map((code) => (
                                        <FiltersPill
                                            key={`to-${code}`}
                                            className={cx('filters-pills__item')}
                                            label={<CountriesFilterPill countryCodes={[code]} />}
                                            withCloser
                                            isCompact
                                            theme={FilterPillThemeEnum.brandDark}
                                            onClick={() => handleOmitFilters([], [code])}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    }
                />
                {!rates.length && requestStatus?.ok && hasQuery && (
                    <TableMessage
                        iconType={TableMessageIconsEnum.empty}
                        title={t('price-settings.rate-matrix.empty.title')}
                        description=""
                        isShowAction={false}
                    />
                )}
                {!rates.length && requestStatus?.ok && !hasQuery && (
                    <TableMessage
                        iconType={TableMessageIconsEnum.notFound}
                        title={t('price-settings.rate-matrix.not-found.title')}
                        description=""
                        isShowAction={false}
                    />
                )}

                {!rates.length && requestStatus?.error && <TableError />}
                <RatesTable
                    selectedRatesSet={selectedRatesSet}
                    onSelectRates={setSelectedRatesSet}
                    onUpdateRate={handleUpdateRate}
                    onOpenUserDetails={handleOpenUserDetails}
                    rates={rates}
                    isLoading={requestStatus?.loading}
                    isDisabled={updateRatesRequest.loading}
                />
                <StickyFooter>
                    <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
                </StickyFooter>
                {isShowCSVImportModal && (
                    <Modal>
                        <ModalContent
                            onClose={() => {
                                setShowCSVImportModal(false);
                            }}
                        >
                            <ImportCsv
                                onClose={() => {
                                    setShowCSVImportModal(false);
                                }}
                            />
                        </ModalContent>
                    </Modal>
                )}
            </ListPageLayout>
        </ContentMargins>
    );
};

export default RateMatrix;
