import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerTransportOrders.scss';
import TransportOrderListPageLayout from 'common/layouts/TransportOrderListPageLayout/TransportOrderListPageLayout';
import { urlFactory } from 'broker-admin/utils/urls';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';

const cx = classNames.bind(styles);

type PropsT = {};

const emptyIndexes: number[] = [];

const PartnerTransportOrders: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const { historyPush } = useHistoryPush();

    const handleOpenTransportOrderDetails = (event: React.MouseEvent, transportOrderId: TransportOrderIdT | null) => {
        if (!transportOrderId) {
            return;
        }

        const url = urlFactory.partnerTransportOrderDetails(partnerType, partnerId, transportOrderId);
        historyPush(event, url);
    };

    return (
        <TransportOrderListPageLayout
            onOpenTransportOrderDetails={handleOpenTransportOrderDetails}
            orderQuickFilterOptionSeparators={emptyIndexes}
            orderQuickFilterOptions={null}
            hasProfileVerificationProblem={false}
        />
    );
});

export default PartnerTransportOrders;
