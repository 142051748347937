import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './RadiusDropdown.scss';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import GeoAreaIcon from 'common/icons/GeoAreaIcon';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { useSelector } from 'react-redux';
import { selectAssignmentRadiuses } from 'broker-admin/store/settings/selectors';

const cx = classNames.bind(styles);

type RadiusT = number | null;

export type RadiusOptionT = {
    label: React.ReactNode;
    value: RadiusT;
};

type PropsT = {
    className?: string;
    radius: RadiusT;
    setRadius: (radius: RadiusT) => void;
};

const RadiusDropdown: React.FC<PropsT> = React.memo((props) => {
    const { className, radius, setRadius } = props;

    const assignmentRadiuses = useSelector(selectAssignmentRadiuses);

    const radiusOptions: Array<RadiusOptionT> = React.useMemo(
        () =>
            assignmentRadiuses.map((radius) => ({
                label: <UnitTypeCount count={radius} type={UnitTypeEnum.kilometersAbbreviation} />,
                value: radius,
            })),
        [assignmentRadiuses],
    );

    return (
        <DropdownInput<RadiusOptionT, RadiusOptionT['value']>
            className={className}
            selectedValue={radius}
            options={radiusOptions}
            onSelect={setRadius}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTrigger={(option) => {
                if (!option) {
                    return null;
                }

                return (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={<GeoAreaIcon fillColor={StyleGuideColorsEnum.brandDark} />}
                        label={option.label}
                    />
                );
            }}
            getOptionValue={(option) => option?.value}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default RadiusDropdown;
