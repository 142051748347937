import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BasicWidgetHeaderActions.scss';
import { PartnerDetailsT } from 'broker-admin/store/partner/details/models';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControl from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import LockIcon from 'common/icons/LockIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { LockPartnerConfirmationDataT } from '../dialogs/LockPartnerConfirmation/models';
import { UnlockPartnerConfirmationDataT } from '../dialogs/UnlockPartnerConfirmation/models';
import UnLockIcon from 'common/icons/UnLockIcon';

type PropsT = {
    onLock: (data: LockPartnerConfirmationDataT | null) => void;
    onUnLock: (data: UnlockPartnerConfirmationDataT | null) => void;
    partnerDetails: PartnerDetailsT | null;
};

const cx = classNames.bind(styles);

const BasicWidgetHeaderActions: React.FC<PropsT> = React.memo((props) => {
    const { partnerDetails, onUnLock, onLock } = props;

    const { t } = useTranslation();

    if (!partnerDetails) {
        return null;
    }

    const partnerId = partnerDetails?.id;
    if (isNil(partnerId)) {
        return null;
    }

    if (partnerDetails?.locked) {
        return (
            <>
                <ColoredStatusLabel
                    label={t('partner.details-tab.blocking.locked-status')}
                    color={StyleGuideColorsEnum.orange}
                    className={cx('status')}
                />
                <Button
                    theme={ButtonThemeEnum.transparent}
                    className={cx('verification')}
                    leftIcon={<UnLockIcon strokeColor={StyleGuideColorsEnum.charcoal} size={DEFAULT_ICON_SIZE} />}
                    onClick={() => onUnLock({ partnerId })}
                    testSelector="unlock-partner"
                >
                    {t('partner.details-tab.blocking.unlock')}
                </Button>
            </>
        );
    }

    const options = [
        {
            label: (
                <DropdownControlOptionLabel
                    label={t('partner.details-tab.blocking.lock')}
                    icon={<LockIcon fillColor={StyleGuideColorsEnum.tomatoRed} size={DEFAULT_ICON_SIZE} />}
                />
            ),
            onSelect: () => {
                onLock({ partnerId });
            },
        },
    ];

    return (
        <DropdownControl
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                    testSelector="lock-partner"
                />
            )}
            testSelector="lock-partner"
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default BasicWidgetHeaderActions;
