import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const SettingsIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd">
                <path d="M.9.9h16.2v16.2H.9z" />
                <path
                    fill={fillColor}
                    fillOpacity="0.3"
                    d="M13.914 6.705l-.473-.817-.857.344-.715.29-.614-.472a3.48 3.48 0 00-.83-.479l-.716-.29-.108-.763-.129-.918h-.944l-.129.911-.108.763-.715.297a3.955 3.955 0 00-.844.493l-.607.459-.71-.284-.856-.35-.473.816.729.567.6.473-.094.762c-.02.203-.034.358-.034.493s.014.29.034.493l.095.762-.601.473-.729.567.473.817.857-.345.715-.29.615.473c.263.202.54.364.83.479l.715.29.108.763.129.918h.938l.128-.911.108-.763.716-.29c.276-.115.553-.277.843-.493l.608-.459.702.284.857.344.473-.817-.73-.567-.6-.473.094-.762A3.62 3.62 0 0012.699 9c0-.142-.013-.29-.034-.493l-.094-.763.6-.472.743-.567zM9 11.7A2.699 2.699 0 016.3 9c0-1.492 1.208-2.7 2.7-2.7 1.492 0 2.7 1.208 2.7 2.7 0 1.492-1.208 2.7-2.7 2.7z"
                />
                <path
                    fill={strokeColor}
                    d="M14.015 9.661c.027-.215.047-.431.047-.661 0-.23-.02-.446-.047-.661l1.425-1.114a.34.34 0 00.08-.432l-1.35-2.336a.335.335 0 00-.296-.168.302.302 0 00-.115.02l-1.681.675a4.953 4.953 0 00-1.14-.662l-.258-1.789a.33.33 0 00-.331-.283h-2.7a.328.328 0 00-.33.283l-.257 1.79c-.412.168-.79.397-1.141.66l-1.68-.674a.376.376 0 00-.122-.02c-.115 0-.23.06-.29.168L2.48 6.793a.333.333 0 00.08.432l1.425 1.114A5.328 5.328 0 003.938 9c0 .223.02.446.047.662L2.56 10.774a.34.34 0 00-.082.432l1.35 2.336a.338.338 0 00.298.168c.04 0 .08-.006.114-.02l1.681-.675c.351.27.729.493 1.14.662l.257 1.788a.33.33 0 00.331.284h2.7c.169 0 .31-.121.33-.284l.257-1.788c.412-.169.79-.399 1.141-.662l1.68.675c.041.014.082.02.122.02.115 0 .23-.06.29-.168l1.35-2.336a.34.34 0 00-.08-.432L14.014 9.66zM12.68 8.507c.027.21.034.351.034.493 0 .142-.014.29-.034.493l-.095.762.601.473.729.567-.473.817-.857-.345-.702-.283-.607.459c-.29.216-.567.378-.844.493l-.716.29-.107.763-.136.911h-.944l-.129-.911-.108-.763-.715-.29a3.838 3.838 0 01-.83-.48l-.615-.472-.715.29-.857.345-.473-.817.729-.567.6-.473-.094-.762c-.02-.21-.034-.365-.034-.5s.014-.29.034-.493l.095-.763-.601-.472-.729-.567.473-.817.857.344.702.284.607-.459c.29-.216.567-.378.844-.493l.716-.29.107-.763.136-.911h.938l.128.911.108.763.716.29c.29.122.56.277.83.48l.614.472.716-.29.857-.345.472.817-.722.574-.6.473.094.762zM9 6.3A2.699 2.699 0 006.3 9c0 1.492 1.208 2.7 2.7 2.7 1.492 0 2.7-1.208 2.7-2.7 0-1.492-1.208-2.7-2.7-2.7zm0 4.05c-.742 0-1.35-.607-1.35-1.35 0-.742.607-1.35 1.35-1.35.742 0 1.35.607 1.35 1.35 0 .742-.607 1.35-1.35 1.35z"
                />
            </g>
        </svg>
    );
};

SettingsIcon.displayName = 'SettingsIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default SettingsIcon;
