import * as React from 'react';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import isNumber from 'lodash/isNumber';

type PropsT = {
    className?: string;
    totalPriceLabel: React.ReactNode;
    totalPrice: number | undefined;
    lineHaulCost: number | undefined;
    tollCost: number | undefined;
    layoverCost: number | undefined;
    fuelCost: number | undefined;
    teamDriveCost: number | undefined;
    otherCosts: number | undefined;
    testSelector?: string;
    shouldAlwaysRenderExpandTrigger?: boolean;
};

const CarrierCostPriceInfoTable: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        totalPrice,
        totalPriceLabel,
        lineHaulCost,
        fuelCost,
        tollCost,
        layoverCost,
        teamDriveCost,
        otherCosts,
        shouldAlwaysRenderExpandTrigger,
    } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const list: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: totalPriceLabel,
            price: totalPrice,
            placeholder: t('common:info-table.placeholders.carrier-not-assigned'),
            list: isNumber(totalPrice)
                ? [
                      {
                          ...commonPriceDetails.lineHaul,
                          price: lineHaulCost,
                      },
                      {
                          ...commonPriceDetails.fuelCost,
                          price: fuelCost,
                      },
                      {
                          ...commonPriceDetails.roadTaxes,
                          price: tollCost,
                      },
                      {
                          ...commonPriceDetails.layoverCost,
                          price: layoverCost,
                      },
                      {
                          ...commonPriceDetails.teamDrive,
                          price: teamDriveCost,
                      },
                      {
                          iconNode: null,
                          name: t('common:order-details.columns.other-costs'),
                          price: otherCosts,
                      },
                  ]
                : [],
        },
    ].filter(isNonNil);

    return (
        <PriceDetails
            className={className}
            list={list}
            shouldAlwaysRenderExpandTrigger={shouldAlwaysRenderExpandTrigger}
        />
    );
});

export default CarrierCostPriceInfoTable;
