import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './EditTimeWindowsForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT, WaypointsFieldEnum } from './constants';
import validateForm from './validate-form';
import { FormikContext, useFormik } from 'formik';
import TimeWindowPicker from 'common/components/forms/TimeWindowPicker/TimeWindowPicker';
import TimeIntervalDurationPillLabel from 'common/layouts/NewOrderPage/ShipmentDetailsForm/TimeIntervalDurationPillLabel/TimeIntervalDurationPillLabel';
import moment from 'moment';
import { clearDateTimezone } from 'common/utils/time';
import { RangeSelectorZoneThemeEnum } from 'common/components/RangeSelector/constants';
import { updateDockingHours } from 'broker-admin/store/dispatch-edit/actions';
import { selectUpdateDispatchRequest } from 'broker-admin/store/dispatch-edit/selectors';
import { prepareQuery } from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/EditTimeWindowsForm/prepare-query';
import { logWarning } from 'common/utils/logger';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { ALL_DAY_RANGE, StyleGuideColorsEnum } from 'common/constants';
import { RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import isEmpty from 'lodash/isEmpty';
import NumberIcon from 'common/icons/NumberIcon';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchDetails: DispatchDetailsT;
    onCancel: () => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.waypoints]: [],
};

// TODO move to utils
const formatTimeInterval = (
    fromDate: string | null | undefined,
    toDate: string | null | undefined,
): [number, number] => {
    let [timeIntervalFrom, timeIntervalTo] = ALL_DAY_RANGE;

    const targetDateFrom = moment(clearDateTimezone(fromDate));
    timeIntervalFrom = targetDateFrom.valueOf() - targetDateFrom.clone().startOf('day').valueOf();

    const targetDateTo = moment(clearDateTimezone(toDate));

    if (!targetDateFrom.isSame(targetDateTo, 'day')) {
        return [timeIntervalFrom, ALL_DAY_RANGE[1]];
    }

    timeIntervalTo = targetDateTo.valueOf() - targetDateTo.clone().startOf('day').valueOf();

    return [timeIntervalFrom, timeIntervalTo];
};

const EditTimeWindowsForm: React.FC<PropsT> = (props) => {
    const { onCancel, dispatchDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const requestStatus = useSelector(selectUpdateDispatchRequest);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const needAppointmentWaypoints = useMemo(() => {
        const waypoints = dispatchDetails?.tours?.[0]?.waypoints || [];

        return waypoints.filter((payloadWaypoint) => {
            return payloadWaypoint?.appointmentByBroker;
        });
    }, [dispatchDetails]);

    const timeIntervals = React.useMemo(() => {
        return needAppointmentWaypoints.map((waypoint) => {
            return formatTimeInterval(waypoint.dateTimeFrom, waypoint.dateTimeTo);
        });
    }, [needAppointmentWaypoints]);

    const ranges = React.useMemo(() => {
        return timeIntervals.map((timeInterval): Array<RangeSelectorRangeT> => {
            return [
                {
                    id: 'available-range',
                    values: timeInterval,
                    theme: RangeSelectorZoneThemeEnum.green,
                    label: '',
                },
            ];
        });
    }, [timeIntervals, t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.waypoints]: timeIntervals.map((timeInterval) => {
                return {
                    [WaypointsFieldEnum.timeWindow]: timeInterval,
                };
            }),
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [timeIntervals]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const query = prepareQuery(values, dispatchDetails, {
                needAppointmentWaypoints,
            });
            const dispatchId = dispatchDetails.id;
            if (!query || !dispatchId) {
                logWarning('empty query or dispatchId');
                return;
            }

            dispatch(updateDockingHours(dispatchId, query));

            formikHelpers.setTouched({});
        },
    });

    const hasFormAnyErrors = React.useMemo(() => {
        const waypointsErrors = formik.errors[FieldsEnum.waypoints] || [];

        if (typeof waypointsErrors === 'string') {
            return true;
        }

        return waypointsErrors.some((waypointErrors) => {
            return !isEmpty(waypointErrors);
        });
    }, [formik.errors]);

    const waypointsFormValues = formik.values[FieldsEnum.waypoints];

    return (
        <FormikContext.Provider value={formik}>
            <div className={cx('wrap')}>
                <div className={cx('title')}>{t('dispatch-details.edit-time-windows.title')}</div>
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('form')}>
                        {waypointsFormValues.map((waypointFormValues, index) => {
                            const fieldName =
                                `${FieldsEnum.waypoints}.${index}.${WaypointsFieldEnum.timeWindow}` as const;
                            const needAppointmentWaypoint = needAppointmentWaypoints[index];
                            const timeInterval = timeIntervals[index];
                            const range = ranges[index];

                            const waypointNumber = (needAppointmentWaypoint.index || 0) + 1;

                            return (
                                <FormikConnectContextField<FormValuesT, typeof fieldName>
                                    className={cx('time-picker')}
                                    name={fieldName}
                                    withoutLabel
                                    key={`waypoint-${index}`}
                                >
                                    {(props, extendsProps) => (
                                        <TimeWindowPicker
                                            range={ALL_DAY_RANGE}
                                            availableValues={timeInterval}
                                            ranges={range}
                                            name={extendsProps.name}
                                            hasStartStep
                                            hasEndStep
                                            icon={
                                                <NumberIcon
                                                    className={cx('time-picker__icon')}
                                                    number={waypointNumber}
                                                    fillColor={StyleGuideColorsEnum.gray}
                                                />
                                            }
                                            values={extendsProps.value}
                                            setFieldValue={(_, value) => {
                                                props.onChange(value);
                                            }}
                                            label={t(
                                                'dispatch-details.edit-time-windows.fields.waypoint-time-window.label',
                                                {
                                                    number: waypointNumber,
                                                },
                                            )}
                                            duration={
                                                <TimeIntervalDurationPillLabel
                                                    isDayRelative
                                                    timeWindow={extendsProps.value}
                                                />
                                            }
                                        />
                                    )}
                                </FormikConnectContextField>
                            );
                        })}
                    </div>
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('action', 'action--cancel')}
                            onClick={onCancel}
                        >
                            {t('dispatch-details.edit-time-windows.actions.cancel')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isDisabled={hasFormAnyErrors || requestStatus.loading}
                            className={cx('action', 'action--set')}
                            isLoading={requestStatus.loading}
                            type="submit"
                        >
                            {t('dispatch-details.edit-time-windows.actions.set')}
                        </Button>
                    </div>
                </form>
            </div>
        </FormikContext.Provider>
    );
};

export default EditTimeWindowsForm;
