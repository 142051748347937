import * as React from 'react';
import { useCallback, useContext, useEffect } from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { useTranslation } from 'react-i18next';
import AccountIcon from 'common/icons/AccountIcon';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { isNonNil } from 'common/utils';
import { useSelector } from 'react-redux';
import { selectCurrentUserUTCOffset } from 'common/store/user/selectors';
import isNumber from 'lodash/isNumber';
import { parseISOTimeZone } from 'common/utils/time';
import TimeZoneContext, {
    TimeZoneEnum,
    TimeZoneT,
} from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/contexts/timezone-context';
import find from 'lodash/find';
import moment from 'moment';
import NumberIcon from 'common/icons/NumberIcon';

export type TimeZoneOptionT = {
    label: React.ReactNode;
    utcOffset: number;
    timeZone: TimeZoneT;
};

type PropsT = {
    className?: string;
    dispatchDetails: DispatchDetailsT | null;
};

const TimeZoneDropdown: React.FC<PropsT> = React.memo((props) => {
    const { className, dispatchDetails } = props;

    const timeZoneContext = useContext(TimeZoneContext);
    const timeZone = timeZoneContext?.value?.timeZone !== undefined ? timeZoneContext.value.timeZone : null;
    const setTimeZoneValue = timeZoneContext?.setValue || null;

    const currentUserUTCOffset = useSelector(selectCurrentUserUTCOffset);
    const userUTCOffset = isNumber(currentUserUTCOffset) ? currentUserUTCOffset : moment().utcOffset();
    // init utcOffset (async user)
    useEffect(() => {
        const currentTimeZone = timeZoneContext?.value?.timeZone;

        if (
            currentTimeZone === TimeZoneEnum.accountTimezone &&
            !isNumber(timeZoneContext?.value.utcOffsetMin) &&
            isNumber(userUTCOffset)
        ) {
            timeZoneContext?.setValue({
                utcOffsetMin: userUTCOffset,
                timeZone: currentTimeZone,
            });
        }
    }, [userUTCOffset]);

    const { t } = useTranslation();

    const timeZoneOptions: Array<TimeZoneOptionT> = React.useMemo(() => {
        const waypoints = dispatchDetails?.tours?.[0]?.waypoints || [];

        const waypointOptions = waypoints.reduce<Array<TimeZoneOptionT>>((acc, waypoint, index) => {
            const waypointUTCOffset = parseISOTimeZone(waypoint?.dateTimeFrom);
            if (!isNumber(waypointUTCOffset)) {
                return acc;
            }

            const number = (waypoint.index || 0) + 1;
            acc.push({
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={<NumberIcon number={number} fillColor={StyleGuideColorsEnum.charcoal} />}
                        label={t('assignment.search-form.timezone-switcher.waypoint-timezone', {
                            number,
                        })}
                    />
                ),
                timeZone: index,
                utcOffset: waypointUTCOffset,
            });

            return acc;
        }, []);

        return [
            ...waypointOptions,
            isNumber(userUTCOffset)
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              withoutPaddings
                              icon={
                                  <AccountIcon
                                      size={DEFAULT_ICON_SIZE}
                                      fillColor={StyleGuideColorsEnum.brandAccent}
                                      strokeColor={StyleGuideColorsEnum.brandDark}
                                  />
                              }
                              label={t('assignment.search-form.timezone-switcher.my-account-timezone')}
                          />
                      ),
                      timeZone: TimeZoneEnum.accountTimezone,
                      utcOffset: userUTCOffset,
                  }
                : null,
        ].filter(isNonNil);
    }, [t, dispatchDetails, userUTCOffset]);

    const renderOption = useCallback((option: TimeZoneOptionT | undefined, placeholder: string | undefined) => {
        if (!option) {
            return placeholder;
        }

        return option.label;
    }, []);

    const getOptionValue = useCallback((option: TimeZoneOptionT) => {
        return option.timeZone;
    }, []);

    const handleSelectOption = useCallback(
        (selectedValue: TimeZoneOptionT['timeZone'] | null) => {
            const selectedOption = find(timeZoneOptions, (option) => {
                return option?.timeZone === selectedValue;
            });

            if (selectedOption && setTimeZoneValue) {
                setTimeZoneValue({
                    timeZone: selectedOption.timeZone,
                    utcOffsetMin: selectedOption.utcOffset,
                });
            }
        },
        [timeZoneOptions, setTimeZoneValue],
    );

    return (
        <DropdownInput<TimeZoneOptionT, TimeZoneOptionT['timeZone'] | null>
            className={className}
            selectedValue={timeZone}
            options={timeZoneOptions}
            onSelect={handleSelectOption}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default TimeZoneDropdown;
