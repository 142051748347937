import { FieldsEnum, FormValuesT, WaypointsFieldEnum } from './constants';
import { ApiUpdateDockingHoursQueryT } from 'common/utils/api/models';
import { DispatchDetailsT, DispatchWaypointT } from 'broker-admin/store/dispatch-details/models';
import moment from 'moment/moment';

type ModsT = {
    needAppointmentWaypoints: Array<DispatchWaypointT>;
};

export const prepareQuery = (
    formValues: FormValuesT,
    dispatchDetails: DispatchDetailsT,
    mods: ModsT,
): ApiUpdateDockingHoursQueryT | null => {
    const { needAppointmentWaypoints } = mods;

    const query: ApiUpdateDockingHoursQueryT = [];

    const waypointsFormValues = formValues[FieldsEnum.waypoints] || [];
    waypointsFormValues.forEach((waypointFormValues, index) => {
        const selectedTimeWindows = waypointFormValues[WaypointsFieldEnum.timeWindow];
        if (!selectedTimeWindows) {
            return;
        }

        const [formHoursFrom, formHoursTo] = selectedTimeWindows;

        const needAppointmentWaypoint = needAppointmentWaypoints[index];
        const { id, dateTimeFrom } = needAppointmentWaypoint;

        const start = moment.parseZone(dateTimeFrom).startOf('day');

        query.push({
            from: moment(start).add(formHoursFrom, 'milliseconds').format(),
            to: moment(start).add(formHoursTo, 'milliseconds').format(),
            id,
        });
    });

    return query;
};
