import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { PartnerTypeEnum, StopEnum } from 'common/utils/api/models';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import DispatchStatusPill from 'common/components/status-pill/DispatchStatusPill/DispatchStatusPill';
import TransportOrderStatusPill from 'common/components/status-pill/TransportOrderStatusPill/TransportOrderStatusPill';
import { urlFactory } from 'broker-admin/utils/urls';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import ContactIcon from 'common/icons/ContactIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import CarrierIcon from 'common/icons/CarrierIcon';
import CommentIcon from 'common/icons/CommentIcon';
import LayoverIcon from 'common/icons/LayoverIcon';
import RouteIcon from 'common/icons/RouteIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import WeightIcon from 'common/icons/WeightIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import { formatDate, formatTimeInterval } from 'common/utils/time';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import { selectDispatchDetails } from 'broker-admin/store/dispatch-details/selectors';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import { convertToKm } from 'common/utils/distance';
import { SpotBidT, SpotRequestStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';

import AsyncTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/AsyncTrailerTypeFormatter/AsyncTrailerTypeFormatter';
import Bids from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/Bids/Bids';
import ShipperIcon from 'common/icons/ShipperIcon';
import ShipperPriceOfferInfoTable, {
    ShipperPriceOfferInfoTablePropsT,
} from 'common/components/info-tables/ShipperPriceOfferInfoTable/ShipperPriceOfferInfoTable';
import CarrierCostPriceInfoTable from 'broker-admin/components/info-tables/CarrierCostPriceInfoTable/CarrierCostPriceInfoTable';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import { isNonNil } from 'common/utils';
import isNil from 'lodash/isNil';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import ShipmentDetailsCard from './ShipmentDetailsCard/ShipmentDetailsCard';
import keyBy from 'lodash/keyBy';
import { DispatchWaypointT } from 'broker-admin/store/dispatch-details/models';
import SpotRequestDetailsTabs from './SpotRequestDetailsTabs/SpotRequestDetailsTabs';
import { SpotDetailsTabEnum } from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/models';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';

type PropsT = {
    id: SpotRequestIdT;
    isDisabledSelectBid?: boolean;
    selectedBidId: BidIdT | null;
    onSelectBid: (bid: SpotBidT) => void;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
};

const cx = classNames.bind(styles);

const PLACED_DATE_FORMAT = 'DD MMM YYYY, HH:mm';
const EXPIRES_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

const SpotRequestDetails: React.FC<PropsT> = React.memo((props) => {
    const { id, selectedBidId, isDisabledSelectBid, onSelectBid, onOpenUserDetails } = props;

    const [selectedTab, setSelectedTab] = React.useState<SpotDetailsTabEnum | null>(SpotDetailsTabEnum.details);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const { details } = useSelector(selectSpotRequestDetails(id));

    const dispatchId = details?.dispatchId || null;
    const dispatchDetails = useSelector(selectDispatchDetails(dispatchId || null));

    const waypointsById = useMemo(() => {
        return keyBy(dispatchDetails?.tours?.[0]?.waypoints, 'id');
    }, [dispatchDetails]);

    const firstShipment = dispatchDetails?.shipments?.[0] || null;
    const firstTour = dispatchDetails?.tours?.[0] || null;
    const firstTransportOrder = firstTour?.transportationOrders?.[0] || null;

    const { t } = useTranslation();

    const entriesInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: <ShipperIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.shipper'),
                value: (
                    <Link
                        to={urlFactory.partnerDetails(PartnerTypeEnum.shipper, dispatchDetails?.order?.shipper?.id)}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {dispatchDetails?.order?.shipper?.name}
                    </Link>
                ),
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                testSelector: 'shipper',
            },
            {
                icon: null,
                name: t('spot-request-details.details.dispatch-id'),
                value: (
                    <Link
                        to={urlFactory.dispatchDetails(dispatchDetails?.id || '-')}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {dispatchDetails?.number}
                    </Link>
                ),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                rightNode: <DispatchStatusPill isSymmetrical status={dispatchDetails?.status} />,
                testSelector: 'dispatch',
            },
        ].filter(isNonNil);
    }, [t, details]);

    const entriesInfoTableRows2 = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: <CarrierIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.carrier'),
                value: details?.carrierId ? (
                    <Link
                        to={urlFactory.partnerDetails(PartnerTypeEnum.carrier, details.carrierId)}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {details?.carrierName}
                    </Link>
                ) : null,
                emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                isBoldValue: true,
                testSelector: 'carrier',
            },
            firstTransportOrder
                ? {
                      icon: null,
                      name: t('spot-request-details.details.transport-order'),
                      value: firstTransportOrder?.id ? (
                          <Link
                              to={urlFactory.partnerTransportOrderDetails(
                                  PartnerTypeEnum.shipper,
                                  firstTransportOrder?.carrier?.id || '-',
                                  firstTransportOrder?.id || '-',
                              )}
                              theme={LinkThemeEnum.boldBrandDark}
                          >
                              {firstTransportOrder?.number}
                          </Link>
                      ) : null,
                      emptyValue: t('common:info-table.placeholders.empty'),
                      isBoldValue: true,
                      rightNode: <TransportOrderStatusPill status={firstTransportOrder?.status} />,
                      testSelector: 'transport-order',
                  }
                : null,
        ].filter(isNonNil);
    }, [t, details]);

    const spotInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: (
                    <CalendarIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                name: t('spot-request-details.details.created'),
                value: formatDate(PLACED_DATE_FORMAT, details?.placedOn),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'created',
            },
            {
                icon: <ContactIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.author'),
                value: (
                    <Link
                        onClick={() => {
                            onOpenUserDetails({
                                partnerType: PartnerTypeEnum.carrier,
                                partnerId: details?.carrierId || '-',
                                userId: details?.placedBy || '-',
                            });
                        }}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {details?.placedByName}
                    </Link>
                ),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'author',
            },
            {
                icon: <TimeWindowIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.expiration'),
                value: formatDate(EXPIRES_DATE_FORMAT, details?.expiresAt),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'expiration',
            },
        ];
    }, [t, details]);

    const shipmentInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            firstTransportOrder
                ? {
                      icon: (
                          <LayoverIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                      ),
                      name: t('spot-request-details.details.layover'),
                      value: (
                          <UnitTypeCount
                              type={UnitTypeEnum.hoursAbbreviation}
                              count={firstTransportOrder?.carrierCost?.layoverCost || 0}
                          />
                      ),
                      isBoldValue: true,
                      testSelector: 'layover',
                      hasBottomBorder: true,
                  }
                : null,
            {
                icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.distance'),
                value: (
                    <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(details?.distance)} />
                ),
                rightNode: dispatchDetails?.priceOffer?.teamDrive ? <TeamDrivePill /> : null,
                isBoldValue: true,
                testSelector: 'distance',
                hasBottomBorder: true,
            },
            {
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('spot-request-details.details.trailer-type'),
                value: <AsyncTrailerTypeFormatter trailerDictId={details?.trailerTypeId} />,
                isBoldValue: true,
                testSelector: 'trailer',
                hasBottomBorder: true,
            },
            {
                icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.emission'),
                value: <EmissionClassFormatter emissionClass={dispatchDetails?.emissionClass} />,
                isBoldValue: true,
                testSelector: 'emission',
                hasBottomBorder: true,
            },
        ].filter(isNonNil);
    }, [t, details]);

    const additionalServicesDetails = React.useMemo(
        (): Array<InfoTableRowT> => [
            {
                icon: null,
                name: t('spot-request-details.details.addition-services'),
                testSelector: 'additional-services',
            },
            ...(dispatchDetails?.additionalServices || []).map((additionalService): InfoTableRowT => {
                return {
                    icon: null,
                    name: '',
                    value: <AsyncAdditionalServiceFormatter type={additionalService.type} />,
                    testSelector: additionalService.type,
                };
            }),
        ],
        [dispatchDetails],
    );

    const loadInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: (
                    <CargoUnitIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('spot-request-details.details.load-units'),
                value: (
                    <UnitTypeCount
                        type={getLoadUnitType(firstShipment?.unitsType)}
                        count={firstShipment?.maxNumberOfUnits}
                    />
                ),
                isBoldValue: true,
                hasBottomBorder: true,
                testSelector: 'units',
            },
            {
                icon: <WeightIcon fillColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.weight'),
                value: <UnitTypeCount count={firstShipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
                isBoldValue: true,
                hasBottomBorder: true,
                testSelector: 'weight',
            },
            {
                icon: <SnowflakeIcon fillColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.temperature'),
                value: (
                    <TemperatureModeFormatter
                        lowestTemperature={firstShipment?.lowestTemperature}
                        highestTemperature={firstShipment?.highestTemperature}
                    />
                ),
                hasBottomBorder: true,
                isBoldValue: true,
                testSelector: 'temperature',
            },
        ];
    }, [t, firstShipment]);

    const bids = React.useMemo((): SpotBidT[] => {
        return details?.bids || [];
    }, [details]);

    const additionalServices = useMemo((): ShipperPriceOfferInfoTablePropsT['additionalServices'] => {
        if (!dispatchDetails?.additionalServices) {
            return [];
        }

        return dispatchDetails.additionalServices.reduce<
            NonNullable<ShipperPriceOfferInfoTablePropsT['additionalServices']>
        >((acc, additionalService) => {
            if (additionalService.type && !isNil(additionalService.id) && !isNil(additionalService.cost)) {
                acc.push({
                    enum: additionalService.type,
                    cost: additionalService.cost,
                    id: additionalService.id,
                });
            }

            return acc;
        }, []);
    }, [dispatchDetails]);

    const commentTableRows = useMemo(() => {
        return [
            {
                icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.comments'),
                value: firstShipment?.description,
                isBoldValue: true,
                testSelector: 'comments',
                emptyValue: t('common:info-table.placeholders.empty'),
            },
        ];
    }, [firstShipment]);

    const renderWaypoint = (waypoint: DispatchWaypointT, index: number) => {
        const isDriveThrough = waypoint?.type === StopEnum.driveThrough;
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <NumberIcon
                        number={index + 1}
                        fillColor={isDriveThrough ? StyleGuideColorsEnum.gray : StyleGuideColorsEnum.charcoal}
                    />
                ),
                name: t('spot-request-details.details.waypoint', {
                    number: index + 1,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...(isDriveThrough
                ? []
                : [
                      {
                          icon: null,
                          name: t('spot-request-details.details.time-slots'),
                          value: formatTimeInterval(waypoint?.dateTimeFrom, waypoint?.dateTimeTo),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'date-time',
                          hasBottomBorder: true,
                      },
                      {
                          icon: null,
                          name: t('spot-request-details.details.company-name'),
                          value: waypoint?.contact?.companyName,
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'contact-company-name',
                          hasBottomBorder: true,
                      },
                      {
                          icon: null,
                          name: t('spot-request-details.details.contact-name'),
                          value: waypoint?.contact?.fullName,
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'contact-name',
                          hasBottomBorder: true,
                      },
                      {
                          icon: null,
                          name: t('spot-request-details.details.contact-phone'),
                          value: waypoint?.contact?.phone,
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'contact-phone',
                          hasBottomBorder: true,
                      },
                      {
                          icon: null,
                          name: t('spot-request-details.details.contact-email'),
                          value: waypoint?.contact?.email,
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'contact-email',
                          hasBottomBorder: true,
                      },
                      {
                          icon: null,
                          name: t('spot-request-details.details.comments'),
                          value: waypoint?.contact?.comment,
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'contact-comment',
                      },
                  ]),
        ];

        return (
            <InfoTable
                key={index}
                shouldRenderIcons
                isCollapsable
                className={cx('table')}
                rows={rows}
                testSelector={`waypoint-${index}`}
            />
        );
    };

    const isShowStatus =
        details?.status !== SpotRequestStatusEnum.canceled && details?.status !== SpotRequestStatusEnum.assigned;

    const stops = details?.stops || [];
    const firstStop = stops[0] || null;
    const lastStop = stops[stops.length - 1] || null;

    return (
        <>
            <div className={cx('container')}>
                <SpotRequestDetailsTabs
                    className={cx('tabs')}
                    isShowStatus={isShowStatus}
                    details={details}
                    selectedTab={selectedTab}
                    onSelectTab={setSelectedTab}
                />
                {selectedTab === SpotDetailsTabEnum.details && (
                    <>
                        <OrderRouteLocations
                            className={cx('route')}
                            origin={firstStop?.address}
                            pickupDockingHoursFrom={firstStop?.timeSlotFrom}
                            pickupDockingHoursTo={firstStop?.timeSlotTo}
                            destination={lastStop?.address}
                            dropOffDockingHoursFrom={lastStop?.timeSlotFrom}
                            dropOffDockingHoursTo={lastStop?.timeSlotTo}
                        />
                        <ExcludedCountries
                            className={cx('excluded-countries')}
                            titleNode={t('spot-request-details.excluded-countries.title')}
                            countryCodes={dispatchDetails?.prohibitedCountries || []}
                            tooltipNode={t('spot-request-details.excluded-countries.tooltip')}
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={entriesInfoTableRows}
                            isCollapsable
                            testSelector="entries-details"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={entriesInfoTableRows2}
                            isCollapsable
                            testSelector="entries-details-2"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={spotInfoTableRows}
                            isCollapsable
                            testSelector="entries-details"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={commentTableRows}
                            testSelector="entries-details"
                        />
                    </>
                )}
                <ShipperPriceOfferInfoTable
                    className={cx('table')}
                    totalPriceLabel={t('spot-request-details.details.shipper-prices')}
                    hasContract={!!dispatchDetails?.order?.shipperContract}
                    totalPrice={dispatchDetails?.priceOffer?.totalPrice}
                    lineHaulCost={dispatchDetails?.priceOffer?.lineHaul}
                    tollCost={dispatchDetails?.priceOffer?.tollCost}
                    tollByRoadType={dispatchDetails?.priceOffer?.tollByRoadType}
                    tranziitServiceFee={dispatchDetails?.priceOffer?.serviceFee}
                    urgentOverprice={dispatchDetails?.priceOffer?.urgentOverprice}
                    layoverCost={dispatchDetails?.priceOffer?.layoverCost}
                    fuelCost={dispatchDetails?.priceOffer?.fuelCost}
                    greenCost={dispatchDetails?.priceOffer?.greenOverprice}
                    additionalServicesCost={dispatchDetails?.priceOffer?.additionalServicesCost}
                    additionalServices={additionalServices}
                    shouldAlwaysRenderExpandTrigger
                />
                <div className={cx('separator')} />
                <CarrierCostPriceInfoTable
                    className={cx('table')}
                    totalPrice={details?.estimatePrice}
                    totalPriceLabel={t('spot-request-details.details.estimate-carrier-cost')}
                    lineHaulCost={dispatchDetails?.carrierEstimatedCost?.lineHaul}
                    tollCost={dispatchDetails?.carrierEstimatedCost?.tollCost}
                    layoverCost={dispatchDetails?.carrierEstimatedCost?.layoverCost}
                    fuelCost={dispatchDetails?.carrierEstimatedCost?.fuelCost}
                    teamDriveCost={dispatchDetails?.carrierEstimatedCost?.teamDriveCost}
                    otherCosts={dispatchDetails?.carrierEstimatedCost?.otherCosts}
                    testSelector="estimate-carrier-cost"
                    shouldAlwaysRenderExpandTrigger
                />
                <div className={cx('separator')} />
                <CarrierCostPriceInfoTable
                    className={cx('table')}
                    totalPrice={details?.winnerPrice}
                    totalPriceLabel={t('spot-request-details.details.carrier-cost')}
                    lineHaulCost={firstTransportOrder?.carrierCost?.lineHaul}
                    tollCost={firstTransportOrder?.carrierCost?.tollCost}
                    layoverCost={firstTransportOrder?.carrierCost?.layoverCost}
                    fuelCost={firstTransportOrder?.carrierCost?.fuelCost}
                    teamDriveCost={firstTransportOrder?.carrierCost?.teamDriveCost}
                    otherCosts={firstTransportOrder?.carrierCost?.otherCosts}
                    testSelector="carrier-cost"
                    shouldAlwaysRenderExpandTrigger
                />
                {selectedTab === SpotDetailsTabEnum.details && (
                    <>
                        <InfoTable shouldRenderIcons rows={shipmentInfoTableRows} testSelector="shipment-details" />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            isCollapsable
                            rows={additionalServicesDetails}
                            testSelector="additional-services"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={loadInfoTableRows}
                            testSelector="load-details"
                        />
                        {dispatchDetails?.tours?.[0]?.waypoints?.map((waypoint, index) => {
                            return renderWaypoint(waypoint, index);
                        })}
                        {dispatchDetails?.shipments?.map((shipment, index) => {
                            return (
                                <ShipmentDetailsCard
                                    key={`shipment-details-card-${index}`}
                                    className={cx('shipment-card')}
                                    isReeferTrailer={!!dispatchDetails?.trailerInfo?.reefer}
                                    shipmentNumber={index + 1}
                                    shipment={shipment}
                                    pickUpWaypoint={waypointsById?.[shipment?.pickupPointId] || null}
                                    dropOffWaypoint={waypointsById?.[shipment?.dropOffPointId] || null}
                                />
                            );
                        })}
                    </>
                )}
                {selectedTab === SpotDetailsTabEnum.bids && (
                    <>
                        <Bids
                            className={cx('bids')}
                            bids={bids}
                            isDisabledSelectBid={isDisabledSelectBid}
                            selectedBidId={selectedBidId}
                            onSelectBid={onSelectBid}
                            estimateSpotRequestPrice={details?.estimatePrice || null}
                            onOpenUserDetails={onOpenUserDetails}
                        />
                    </>
                )}
            </div>
        </>
    );
});

export default SpotRequestDetails;
