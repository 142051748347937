import BaseTranziitApi, { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';
import authApi from 'common/utils/api/auth/auth-api';
import { MS_IN_MIN } from 'common/utils/time';
import { OverwritePageRequestWithSortT } from 'common/utils/api/models';
import { ApiSpotSystemSettingsT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

class SpotBrokerTranziitApi extends BaseTranziitApi {
    private static instance: SpotBrokerTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '/spot/api',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): SpotBrokerTranziitApi {
        if (!SpotBrokerTranziitApi.instance) {
            SpotBrokerTranziitApi.instance = new SpotBrokerTranziitApi();
        }

        return SpotBrokerTranziitApi.instance;
    }

    fetchSpotRequests = async (
        query: OverwritePageRequestWithSortT<SpotApiPaths.SpotApiV1BrokerSpot.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1BrokerSpot.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1BrokerSpot.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/spot/`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotRequestsStats = async (): Promise<
        TranziitApiResultT<SpotApiPaths.SpotApiV1BrokerSpotStatuses.Get.Responses.$200>
    > => {
        return this.doFetch<SpotApiPaths.SpotApiV1BrokerSpotStatuses.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/spot/statuses`,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotRequestDetails = async (
        id: SpotApiPaths.SpotApiV1BrokerSpot$SpotId.Get.Parameters.SpotId,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1BrokerSpot$SpotId.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1BrokerSpot$SpotId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/broker/spot/${id}`,
            expectedResponseContentType: 'json',
        });
    };

    cancelSpotRequestByTour = async (
        tourId: SpotApiPaths.SpotApiV1BrokerSpotCancelByTour$TourId.Post.Parameters.TourId,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1BrokerSpotCancelByTour$TourId.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1BrokerSpotCancelByTour$TourId.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/spot/cancel-by-tour/${tourId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    assignBidToSpotRequest = async (
        spotRequestId: SpotApiPaths.SpotApiV1BrokerSpotAssign$SpotId.Post.Parameters.SpotId,
        query: SpotApiPaths.SpotApiV1BrokerSpotAssign$SpotId.Post.QueryParameters,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1BrokerSpotAssign$SpotId.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1BrokerSpotAssign$SpotId.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/broker/spot/assign/${spotRequestId}`,
            query,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchSystemSettings = async (): Promise<TranziitApiResultT<ApiSpotSystemSettingsT>> => {
        const [error, response] = await this.doFetch<SpotApiPaths.SpotApiV1Settings.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/settings`,
            expectedResponseContentType: 'json',
        });

        if (error) {
            return [error, null];
        }

        const apiSettings = response?.reduce<ApiSpotSystemSettingsT>((acc, item) => {
            const { name, value } = item;

            acc[name] = value;

            return acc;
        }, {});

        return [null, apiSettings || null];
    };

    updateSystemSettings = async (
        settingsChanges: Partial<ApiSpotSystemSettingsT>,
    ): Promise<TranziitApiResultT<null>> => {
        const body = Object.entries(settingsChanges).reduce<ApiPaths.ApiV1Settings.Put.RequestBody>(
            (acc, [key, value]) => {
                acc.push({
                    // @ts-ignore
                    name: key,
                    // @ts-ignore
                    value,
                });

                return acc;
            },
            [],
        );

        return this.doFetch<null>({
            method: 'POST',
            path: '/v1/settings',
            data: body,
            expectedResponseContentType: 'unknown',
        });
    };
}

const spotBrokerTranziitApi = SpotBrokerTranziitApi.getInstance();

export default spotBrokerTranziitApi;
