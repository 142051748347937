import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PriceSettingsRules.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RulesTable from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/RulesTable/RulesTable';
import { StyleGuideColorsEnum } from 'common/constants';
import { RulesSortEnum } from 'common/utils/api/models';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import {
    selectCreateRuleRequest,
    selectDeleteRulesRequest,
    selectRulesByIds,
    selectRulesPages,
    selectRulesTotal,
    selectUpdateRulesRequest,
} from 'broker-admin/store/price-settings/rules/selectors';
import {
    activateRules,
    deactivateRules,
    deleteRules,
    fetchRulesPage,
} from 'broker-admin/store/price-settings/rules/actions';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableActions, { TableActionOptionT } from 'common/components/Table/TableActions/TableActions';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CircleIcon from 'common/icons/CircleIcon';
import DeleteIcon from 'common/icons/DeleteIcon';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import TableError from 'common/components/Table/TableError/TableError';
import { prepareFetchPageQuery } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/prepare-fetch-page-query';
import ShipperFilterPill from 'common/components/filter-pills/ShipperFilterPill/ShipperFilterPill';
import GroupFilterPill from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/filter-pills/GroupFilterPill/GroupFilterPill';
import PriceComponentFilterPill from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/filter-pills/PriceComponentFilterPill/PriceComponentFilterPill';
import RepeatStrategyFilterPill from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/filter-pills/RepeatStrategyFilterPill/RepeatStrategyFilterPill';
import CountriesFilterPill from 'common/components/filter-pills/CountriesFilterPill/CountriesFilterPill';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import TrailerDictIdsFilterPill from 'common/components/filter-pills/TrailerDictIdsFilterPill/TrailerDictIdsFilterPill';
import { useCheckOpenedSidebar, useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { rulesPaginationChannel, rulesRefreshChannel } from 'broker-admin/store/price-settings/rules/channels';
import { useQueryParams } from 'use-query-params';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { InferChannelEventT } from 'common/utils/view-event-channel';

const cx = classNames.bind(styles);

type PropsT = {};

const PriceSettingsRules: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { partnerId, partnerType } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in price settings rules, empty userId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const [selectedRulesSet, setSelectedRulesSet] = React.useState<Set<RuleT>>(new Set());

    const total = useSelector(selectRulesTotal);
    const rulesPages = useSelector(selectRulesPages);
    const rulesById = useSelector(selectRulesByIds);
    const deleteRulesRequest = useSelector(selectDeleteRulesRequest);
    const createRuleRequest = useSelector(selectCreateRuleRequest);
    const updateRulesRequest = useSelector(selectUpdateRulesRequest);

    const isOpenedFilters = useCheckOpenedSidebar(BrokerSidebarsTypeEnum.priceSettingsRuleFilter);
    const openFilters = React.useCallback(() => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.priceSettingsRuleFilter,
        });
    }, [openLeftSidebar]);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.rulesPage]: createPageNumberParam(),
        [QueryKeysEnum.rulesSort]: createSortParams<RulesSortEnum>({
            value: RulesSortEnum.name,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.rulesFilters]: createJsonParams<QueryFiltersT>({}),
    });

    const selectedSort = query[QueryKeysEnum.rulesSort];
    const pageNumber = query[QueryKeysEnum.rulesPage];
    const queryFilters = query[QueryKeysEnum.rulesFilters];
    const page = rulesPages[pageNumber];

    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRulesPage(pageNumber, query));
    }, [pageNumber, selectedSort, queryFilters]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRulesPage(pageNumber, query, { isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchRulesPage(pageNumber, query, { isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters]);
    useChannelSubscribe(rulesRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        setSelectedRulesSet(new Set());
    }, [ids]);

    const rules = React.useMemo(() => {
        return (ids || []).map((id): RuleT => rulesById[id]);
    }, [ids, rulesById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            setSelectedRulesSet(new Set());

            changeQuery({
                [QueryKeysEnum.rulesPage]: pageNumber,
            });
        },
        [setSelectedRulesSet, query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof rulesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(rulesPaginationChannel, setPageHandler);

    const sortOptions: Array<SortDropdownOptionT<RulesSortEnum>> = React.useMemo(
        () => [
            {
                label: t('price-settings.rules.sorts.triggers.name'),
                value: RulesSortEnum.name,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<RulesSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.rulesPage]: 0,
            [QueryKeysEnum.rulesSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.rulesPage]: 0,
            [QueryKeysEnum.rulesFilters]: selectedQueryFilters,
        });
    };

    const resetQueryFilters = () => {
        changeQuery({
            [QueryKeysEnum.rulesFilters]: undefined,
        });
    };

    const showAddRuleForm = (): void => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.editPriceSettingsRuleSidebar,
            isNewRule: true,
        });
    };

    const hasQueryFilters = !!Object.keys(queryFilters).length;

    const tableActionOptions: Array<TableActionOptionT> = [
        {
            label: (
                <DropdownControlOptionLabel
                    icon={<CircleIcon borderColor={StyleGuideColorsEnum.brandAccent} />}
                    label={t('price-settings.rules.actions.activate')}
                />
            ),
            onSelect: () => {
                const ruleIds = [...selectedRulesSet].map((rule) => String(rule.id));
                dispatch(activateRules(ruleIds));
            },
            testSelector: 'activate',
        },
        {
            label: (
                <DropdownControlOptionLabel
                    icon={<CircleIcon borderColor={StyleGuideColorsEnum.tomatoRed} />}
                    label={t('price-settings.rules.actions.deactivate')}
                />
            ),
            onSelect: () => {
                const ruleIds = [...selectedRulesSet].map((rule) => String(rule.id));
                dispatch(deactivateRules(ruleIds));
            },
            testSelector: 'deactivate',
        },
        {
            label: (
                <DropdownControlOptionLabel
                    icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                    label={t('price-settings.rules.actions.delete')}
                />
            ),
            onSelect: () => {
                const ruleIds = [...selectedRulesSet].map((rule) => String(rule.id));
                dispatch(deleteRules(ruleIds));
            },
            testSelector: 'delete',
        },
    ];

    const isDisabled = updateRulesRequest.loading || deleteRulesRequest.loading || createRuleRequest.loading;

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) =>
                    t('price-settings.rules.filters.labels.name', {
                        value: queryFilters[QueryFiltersKeysEnum.rulesName],
                    }),
                clearKeys: [QueryFiltersKeysEnum.rulesName],
            },
            {
                render: (queryFilters) => (
                    <Trans
                        i18nKey="price-settings.rules.filters.labels.group"
                        components={{
                            value: <GroupFilterPill group={queryFilters[QueryFiltersKeysEnum.rulesGroup]} />,
                        }}
                    />
                ),
                clearKeys: [QueryFiltersKeysEnum.rulesGroup],
            },
            {
                render: (queryFilters) => {
                    const trailerTypeIds = queryFilters[QueryFiltersKeysEnum.rulesTrailerTypeIds];

                    return (
                        <Trans
                            i18nKey="price-settings.rules.filters.labels.trailer-types"
                            components={{
                                value: <TrailerDictIdsFilterPill trailerDictIds={trailerTypeIds} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.rulesTrailerTypeIds],
            },
            {
                render: (queryFilters) => {
                    const countryCodes = queryFilters[QueryFiltersKeysEnum.rulesFromCountryCodes];

                    return (
                        <Trans
                            i18nKey="price-settings.rules.filters.labels.from-countries"
                            components={{
                                value: <CountriesFilterPill countryCodes={countryCodes} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.rulesFromCountryCodes],
            },
            {
                render: (queryFilters) =>
                    t('price-settings.rules.filters.labels.from-zip-codes', {
                        value: queryFilters[QueryFiltersKeysEnum.rulesFromCountryZipCodes],
                    }),
                clearKeys: [QueryFiltersKeysEnum.rulesFromCountryZipCodes],
            },
            {
                render: (queryFilters) => {
                    const countryCodes = queryFilters[QueryFiltersKeysEnum.rulesToCountryCodes];

                    return (
                        <Trans
                            i18nKey="price-settings.rules.filters.labels.to-countries"
                            components={{
                                value: <CountriesFilterPill countryCodes={countryCodes} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.rulesToCountryCodes],
            },
            {
                render: (queryFilters) =>
                    t('price-settings.rules.filters.labels.to-zip-codes', {
                        value: queryFilters[QueryFiltersKeysEnum.rulesToCountryZipCodes],
                    }),
                clearKeys: [QueryFiltersKeysEnum.rulesToCountryZipCodes],
            },
            {
                render: (queryFilters) => (
                    <Trans
                        i18nKey="price-settings.rules.filters.labels.repeat-strategy"
                        components={{
                            value: (
                                <RepeatStrategyFilterPill
                                    repeatStrategy={queryFilters[QueryFiltersKeysEnum.rulesRepeatStrategy]}
                                />
                            ),
                        }}
                    />
                ),
                clearKeys: [QueryFiltersKeysEnum.rulesRepeatStrategy],
            },
            {
                render: (queryFilters) =>
                    t('price-settings.rules.filters.labels.valid-from', {
                        value: queryFilters[QueryFiltersKeysEnum.rulesValidFrom],
                    }),
                clearKeys: [QueryFiltersKeysEnum.rulesValidFrom],
            },
            {
                render: (queryFilters) =>
                    t('price-settings.rules.filters.labels.valid-till', {
                        value: queryFilters[QueryFiltersKeysEnum.rulesValidTill],
                    }),
                clearKeys: [QueryFiltersKeysEnum.rulesValidTill],
            },
            {
                render: (queryFilters) => (
                    <Trans
                        i18nKey="price-settings.rules.filters.labels.impact-price-components"
                        components={{
                            value: (
                                <PriceComponentFilterPill
                                    priceComponent={queryFilters[QueryFiltersKeysEnum.rulesImpactPriceComponent]}
                                />
                            ),
                        }}
                    />
                ),
                clearKeys: [QueryFiltersKeysEnum.rulesImpactPriceComponent],
            },
            {
                render: (queryFilters) => (
                    <Trans
                        i18nKey="price-settings.rules.filters.labels.shipper"
                        components={{
                            value: <ShipperFilterPill shipperId={queryFilters[QueryFiltersKeysEnum.rulesShipperId]} />,
                        }}
                    />
                ),
                clearKeys: [QueryFiltersKeysEnum.rulesShipperId],
            },
        ],
        [],
    );

    const handleActiveRule = (ruleId: RuleIdT) => {
        dispatch(activateRules([ruleId]));
    };

    const handleDeactiveRule = (ruleId: RuleIdT) => {
        dispatch(deactivateRules([ruleId]));
    };

    const handleDeleteRule = (ruleId: RuleIdT) => {
        dispatch(deleteRules([ruleId]));
    };

    const handleEditRule = (event: React.MouseEvent, rule: RuleT) => {
        const ruleId = rule.id;

        if (!ruleId) {
            logWarning(`failed to edit rule, empty rule.id`);
            return;
        }

        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.editPriceSettingsRuleSidebar,
            editableRuleId: String(ruleId),
        });
    };

    return (
        <>
            <ContentMargins>
                <ListPageLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    testSelector="rules"
                                    className={cx('filters-trigger')}
                                    title={t('price-settings.rules.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openFilters}
                                />
                                <SortDropdown
                                    testSelector="rules"
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                            </>
                        }
                        rightToolsNode={
                            <>
                                {!!selectedRulesSet.size && (
                                    <TableActions
                                        className={cx('actions')}
                                        options={tableActionOptions}
                                        trigger={t('common:actions-trigger')}
                                        isDisabled={isDisabled}
                                        testSelector="rule"
                                    />
                                )}
                                <Button
                                    theme={ButtonThemeEnum.primary}
                                    onClick={showAddRuleForm}
                                    testSelector="add-rule"
                                >
                                    {t('price-settings.rules.form.add-trigger')}
                                </Button>
                            </>
                        }
                        filterTagsNode={
                            <FilterPills<QueryFiltersT>
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                                config={filterPillsConfig}
                                testSelector="rules"
                            />
                        }
                    />
                    {!rules.length && requestStatus?.ok && hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.notFound}
                            title={t('price-settings.rules.table.messages.not-found.title')}
                            description={t('price-settings.rules.table.messages.not-found.description')}
                            isShowAction
                            actionTitle={t('price-settings.rules.table.messages.not-found.action')}
                            actionTheme={ButtonThemeEnum.secondary}
                            onActionClick={resetQueryFilters}
                            testSelector="not-found"
                        />
                    )}
                    {!rules.length && requestStatus?.ok && !hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.empty}
                            title={t('price-settings.rules.table.messages.empty.title')}
                            description={t('price-settings.rules.table.messages.empty.description')}
                            isShowAction
                            actionTitle={t('price-settings.rules.table.messages.empty.action')}
                            onActionClick={showAddRuleForm}
                            testSelector="empty"
                        />
                    )}
                    <RulesTable
                        rules={rules}
                        isDisabled={isDisabled}
                        isLoading={requestStatus?.loading}
                        selectedRulesSet={selectedRulesSet}
                        onSelectRules={setSelectedRulesSet}
                        onClickRow={handleEditRule}
                        onActiveRule={handleActiveRule}
                        onDeactiveRule={handleDeactiveRule}
                        onDeleteRule={handleDeleteRule}
                        onOpenUserDetails={handleOpenUserDetails}
                    />
                    {!rules.length && requestStatus?.error && <TableError />}
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
        </>
    );
});

export default PriceSettingsRules;
