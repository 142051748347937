import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';
import values from 'lodash/values';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';

const REQUIRED_FIELDS = values<FieldsEnum>(FieldsEnum);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),

        ...checkMinValue(t, [FieldsEnum.urgentOrderProcessingDelay], values, 1),
        ...checkMaxValue(t, [FieldsEnum.urgentOrderProcessingDelay], values, 24),

        ...checkMinValue(t, [FieldsEnum.timeSlotsDelegationLimit], values, 1),
        ...checkMaxValue(t, [FieldsEnum.timeSlotsDelegationLimit], values, 24),

        ...checkMinValue(t, [FieldsEnum.regularOrderProcessingDelay], values, 1),
        ...checkMaxValue(t, [FieldsEnum.regularOrderProcessingDelay], values, 48),

        ...checkMinValue(t, [FieldsEnum.minWindowSize], values, 30),
        ...checkMaxValue(t, [FieldsEnum.minWindowSize], values, 120),

        ...checkMaxValue(t, [FieldsEnum.maxWindowSize], values, 360),

        ...checkMinValue(t, [FieldsEnum.orderCreationSeconds], values, 1),
        ...checkMaxValue(t, [FieldsEnum.orderCreationSeconds], values, 600),

        ...checkMinValue(t, [FieldsEnum.mapPrecisionDelta], values, 0),
        ...checkMaxValue(t, [FieldsEnum.mapPrecisionDelta], values, 500),
    };

    const minWindowSize = parseSimpleNumber(values[FieldsEnum.minWindowSize]);
    const maxWindowSize = parseSimpleNumber(values[FieldsEnum.maxWindowSize]);
    if (isNumber(minWindowSize) && isNumber(maxWindowSize) && maxWindowSize <= minWindowSize) {
        errors[FieldsEnum.maxWindowSize] = t(
            'price-settings.new-order-parameters-form.errors.less-than-minimum-time-window',
        );
    }

    const urgentOrderProcessingDelay = parseSimpleNumber(values[FieldsEnum.urgentOrderProcessingDelay]);
    const regularOrderProcessingDelay = parseSimpleNumber(values[FieldsEnum.regularOrderProcessingDelay]);
    if (
        isNumber(urgentOrderProcessingDelay) &&
        isNumber(regularOrderProcessingDelay) &&
        regularOrderProcessingDelay < urgentOrderProcessingDelay
    ) {
        errors[FieldsEnum.regularOrderProcessingDelay] = t(
            'price-settings.new-order-parameters-form.errors.less-than-urgent',
        );
    }

    return errors;
};

export default validate;
