import * as React from 'react';
import { useMemo } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Bids.scss';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import { useTranslation } from 'react-i18next';
import { BidsQuickFilterEnum, BidsSortEnum } from './constants';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import ButtonGroup from 'design-system/components/ButtonGroup/ButtonGroup';
import BidDetails from '../BidDetails/BidDetails';
import BidMessage from '../BidMessage/BidMessage';
import BigEmptyIcon from 'common/icons/BigEmptyIcon';
import { SpotBidT } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { useBidQuickFilterCounts, useBidsFilter } from './hooks/use-bids-filter';
import { useBidsSort } from './hooks/use-bids-sort';
import { ApiSpotBidStatusT, PartnerTypeEnum, SpotBidStatusEnum } from 'common/utils/api/models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

type PropsT = {
    className?: string;
    bids: Array<SpotBidT | null | undefined>;
    isDisabledSelectBid?: boolean;
    selectedBidId: BidIdT | null;
    onSelectBid: (bid: SpotBidT) => void;
    estimateSpotRequestPrice: number | null;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
};

const cx = classNames.bind(styles);

const DEFAULT_SORT: PageSortT<BidsSortEnum> = {
    value: BidsSortEnum.price,
    direction: SortDirectionEnum.ASC,
};

const QUICK_FILTER_ORDER: Array<BidsQuickFilterEnum> = [
    BidsQuickFilterEnum.accepted,
    BidsQuickFilterEnum.match,
    BidsQuickFilterEnum.inactive,
];

const quickFilterLabels: Record<BidsQuickFilterEnum, string> = {
    [BidsQuickFilterEnum.accepted]: 'spot-request-details.bids.quick-filters.accepted',
    [BidsQuickFilterEnum.match]: 'spot-request-details.bids.quick-filters.match',
    [BidsQuickFilterEnum.inactive]: 'spot-request-details.bids.quick-filters.inactive',
};

const quickFilterPillTheme: Record<BidsQuickFilterEnum, PillLabelThemeEnum> = {
    [BidsQuickFilterEnum.accepted]: PillLabelThemeEnum.orange,
    [BidsQuickFilterEnum.match]: PillLabelThemeEnum.slate,
    [BidsQuickFilterEnum.inactive]: PillLabelThemeEnum.grey,
};

const availableSelectBidStatusSet = new Set<ApiSpotBidStatusT>([SpotBidStatusEnum.accepted]);

const Bids: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        bids,
        isDisabledSelectBid,
        selectedBidId,
        onSelectBid,
        estimateSpotRequestPrice,
        onOpenUserDetails,
    } = props;

    const { t } = useTranslation();

    const [selectedSort, setSort] = React.useState<PageSortT<BidsSortEnum>>(DEFAULT_SORT);

    const [selectedQuickFilter, setQuickFilter] = React.useState<BidsQuickFilterEnum>(BidsQuickFilterEnum.accepted);

    const bidCounts = useMemo(() => {
        return useBidQuickFilterCounts(bids);
    }, [bids]);

    const filteredBids = useMemo(() => {
        return useBidsFilter(selectedQuickFilter, bids);
    }, [selectedQuickFilter, bids]);

    const sortedBids = useMemo(() => {
        return useBidsSort(selectedSort, filteredBids);
    }, [selectedSort, filteredBids]);

    const sortOptions: Array<SortDropdownOptionT<BidsSortEnum>> = React.useMemo(
        () => [
            {
                label: t('spot-request-details.bids.sorts.date'),
                value: BidsSortEnum.date,
            },
            {
                label: t('spot-request-details.bids.sorts.price'),
                value: BidsSortEnum.price,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<BidsSortEnum>) => {
        setSort(sort);
    };

    return (
        <div className={cs(cx('inner'), className)}>
            <div className={cx('header')}>
                <div className={cx('header_tabs', 'filters')}>
                    <ButtonGroup>
                        {QUICK_FILTER_ORDER.map((quickFilter) => {
                            return (
                                <Button
                                    key={quickFilter}
                                    className={cx('filters__button')}
                                    theme={ButtonThemeEnum.transparent}
                                    onClick={() => {
                                        setQuickFilter(quickFilter);
                                    }}
                                    isNarrow
                                    isToggled={quickFilter === selectedQuickFilter}
                                    leftIcon={
                                        <PillLabel isSymmetrical theme={quickFilterPillTheme[quickFilter]} isRounded>
                                            <span className={cx('filters__count')}>{bidCounts[quickFilter]}</span>
                                        </PillLabel>
                                    }
                                >
                                    {t(quickFilterLabels[quickFilter])}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </div>
                <SortDropdown
                    className={cx('header_sort')}
                    testSelector="bids"
                    overlayPosition={SortDropdownOverlayPositionEnum.right}
                    selectedValue={selectedSort}
                    options={sortOptions}
                    onSelect={handleSelectSort}
                />
            </div>
            {sortedBids.map((bid) => {
                const isDisabledBid = !bid?.status || !availableSelectBidStatusSet.has(bid.status);

                return (
                    <BidDetails
                        key={bid?.id}
                        className={cx('bid')}
                        isDisabled={isDisabledSelectBid || isDisabledBid}
                        isSelected={selectedBidId === bid?.id}
                        bid={bid}
                        onSelect={onSelectBid}
                        estimateSpotRequestPrice={estimateSpotRequestPrice}
                        onOpenUserDetails={onOpenUserDetails}
                    />
                );
            })}
            {!sortedBids.length && selectedQuickFilter !== BidsQuickFilterEnum.match && (
                <BidMessage
                    key="empty-message"
                    icon={<BigEmptyIcon />}
                    title={t('spot-request-details.bids.messages.empty.title')}
                />
            )}
            {!sortedBids.length && selectedQuickFilter === BidsQuickFilterEnum.match && (
                <BidMessage
                    key="no-match-message"
                    icon={<BigEmptyIcon />}
                    title={t('spot-request-details.bids.messages.no-match.title')}
                    description={t('spot-request-details.bids.messages.no-match.description')}
                />
            )}
        </div>
    );
});

export default Bids;
