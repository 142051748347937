import { QueryFiltersT } from 'common/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { FieldsEnum, FormValuesT } from './constants';
import { getDateFromDate, getEndDayFromDateZero, getStartDayFromDateZero } from 'common/utils/time';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.dispatcherId]: values[FieldsEnum.dispatcherId] || undefined,
        [QueryFiltersKeysEnum.dispatcherName]: values[FieldsEnum.dispatcherName] || undefined,

        [QueryFiltersKeysEnum.keyAccountManagerId]: values[FieldsEnum.keyAccountManagerId] || undefined,
        [QueryFiltersKeysEnum.keyAccountManagerName]: values[FieldsEnum.keyAccountManagerName] || undefined,

        [QueryFiltersKeysEnum.shipperId]: values[FieldsEnum.shipperId] || undefined,
        [QueryFiltersKeysEnum.shipperName]: values[FieldsEnum.shipperName] || undefined,

        [QueryFiltersKeysEnum.carrierId]: values[FieldsEnum.carrierId] || undefined,
        [QueryFiltersKeysEnum.carrierName]: values[FieldsEnum.carrierName] || undefined,

        [QueryFiltersKeysEnum.dictTrailerId]: values[FieldsEnum.dictTrailerId] || undefined,

        [QueryFiltersKeysEnum.address]: simpleStringFormatter(values[FieldsEnum.address]) || undefined,

        [QueryFiltersKeysEnum.pickupDateFrom]: undefined,
        [QueryFiltersKeysEnum.pickupDateTo]: undefined,

        [QueryFiltersKeysEnum.dropOffDateFrom]: undefined,
        [QueryFiltersKeysEnum.dropOffDateTo]: undefined,

        [QueryFiltersKeysEnum.createdDateFrom]: undefined,
        [QueryFiltersKeysEnum.createdDateTo]: undefined,
    };

    const pickupDates = values[FieldsEnum.pickupDates];
    if (pickupDates?.from) {
        const date = getDateFromDate(pickupDates.from);
        queryFilters[QueryFiltersKeysEnum.pickupDateFrom] = getStartDayFromDateZero(date);
    }

    if (pickupDates?.to) {
        const date = getDateFromDate(pickupDates.to);
        queryFilters[QueryFiltersKeysEnum.pickupDateTo] = getEndDayFromDateZero(date);
    }

    const dropoffDates = values[FieldsEnum.dropoffDates];
    if (dropoffDates?.from) {
        const date = getDateFromDate(dropoffDates.from);
        queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] = getStartDayFromDateZero(date);
    }

    if (dropoffDates?.to) {
        const date = getDateFromDate(dropoffDates.to);
        queryFilters[QueryFiltersKeysEnum.dropOffDateTo] = getEndDayFromDateZero(date);
    }

    const createDates = values[FieldsEnum.createDates];
    if (createDates?.from) {
        const date = getDateFromDate(createDates.from);
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = getStartDayFromDateZero(date);
    }

    if (createDates?.to) {
        const date = getDateFromDate(createDates.to);
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = getEndDayFromDateZero(date);
    }

    return queryFilters;
};

export default getQueryFilters;
