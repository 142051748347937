import * as React from 'react';
import { ApiDispatchStatusT, DispatchStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiDispatchStatusT | null | undefined;
};

export const DISPATCH_STATUS_T_MAP: Record<ApiDispatchStatusT, string> = {
    [DispatchStatusEnum.carrierUnassigned]: 'common:dispatch-status.CARRIER_UNASSIGNED',
    [DispatchStatusEnum.assetsUnassigned]: 'common:dispatch-status.ASSETS_UNASSIGNED',
    [DispatchStatusEnum.driverUnassigned]: 'common:dispatch-status.DRIVER_UNASSIGNED',
    [DispatchStatusEnum.declined]: 'common:dispatch-status.DECLINED',
    [DispatchStatusEnum.assigned]: 'common:dispatch-status.ASSIGNED',
    [DispatchStatusEnum.inTransit]: 'common:dispatch-status.IN_TRANSIT',
    [DispatchStatusEnum.canceled]: 'common:dispatch-status.CANCELED',
    [DispatchStatusEnum.done]: 'common:dispatch-status.DONE',
    [DispatchStatusEnum.unknown]: 'common:dispatch-status.UNKNOWN',
};

const DispatchStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(DISPATCH_STATUS_T_MAP[status]);
});

export default DispatchStatus;
