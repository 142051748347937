import * as React from 'react';
import { hot } from 'react-hot-loader';

import Loadable from 'react-loadable';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from 'common/utils/history';
import Loader from 'common/components/LoaderTitle/LoaderTitle';
import { routesEnum } from 'broker-admin/constants';

import store from 'broker-admin/store';

import '@animated-burgers/burger-squeeze/dist/styles.css';

import classNames from 'classnames/bind';
import styles from './App.scss';
import ChangePasswordPage from 'common/layouts/ChangePasswordPage/ChangePasswordPage';
import ErrorModal from 'common/components/ErrorModal/ErrorModal';
import AuthLoader from 'common/layouts/AuthLoader/AuthLoader';
import UiContext from 'common/contexts/ui-context';
import useMenuVisibility from 'common/utils/hooks/useMenuVisibility';
import {
    getNotificationLink,
    renderNotificationToast,
    renderAlertToast,
    renderNotification,
} from '../../components/toasts/utils';
import OverlaysManager from 'common/components/OverlaysManager/OverlaysManager';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import PartnerContext from 'common/contexts/partner-context';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { DEFAULT_BROKER_COMPANY_ID } from 'common/constants';
import NotificationSidebarContext from 'common/contexts/notification-sidebar-context';
import CommonUrlFactoryContext, { CommonUrlFactoryContextT } from 'common/contexts/common-url-factory-context';
import { urlFactory } from 'broker-admin/utils/urls';
import { useFeaturesContextValue } from 'common/utils/hooks/useFeaturesContextValue';
import FeaturesContext from 'common/contexts/features';

const cx = classNames.bind(styles);

type PropsT = {};

const SignInPage = Loadable({
    loader: () => import(/* webpackChunkName: "SignInPage" */ 'common/layouts/SignInPage/SignInPage'),
    loading() {
        return <Loader />;
    },
});

const SignUpPage = Loadable({
    loader: () => import(/* webpackChunkName: "SignUpPage" */ '../SignUpPage/SignUpPage'),
    loading() {
        return <Loader />;
    },
});

const ContinueSignUpPage = Loadable({
    loader: () =>
        import(/* webpackChunkName: "ContinueSignUpPage" */ 'common/layouts/ContinueSignUpPage/ContinueSignUpPage'),
    loading() {
        return <Loader />;
    },
});

const ForgotPasswordPage = Loadable({
    loader: () =>
        import(/* webpackChunkName: "ForgotPasswordPage" */ 'common/layouts/ForgotPasswordPage/ForgotPasswordPage'),
    loading() {
        return <Loader />;
    },
});

const ConfirmEmailPage = Loadable({
    loader: () => import(/* webpackChunkName: "ConfirmEmailPage" */ 'common/layouts/ConfirmEmailPage/ConfirmEmailPage'),
    loading() {
        return <Loader />;
    },
});

const ClientPage = Loadable({
    loader: () => import(/* webpackChunkName: "ClientPage" */ '../ClientPage/ClientPage'),
    loading() {
        return <Loader />;
    },
});

const NotFoundPage = Loadable({
    loader: () => import(/* webpackChunkName: "NotFoundPage" */ 'common/layouts/NotFoundPage/NotFoundPage'),
    loading() {
        return <Loader />;
    },
});

const App: React.FC<PropsT> = (props) => {
    const menu = useMenuVisibility();

    const contextValue = React.useMemo(() => {
        return {
            menu,
        };
    }, [menu]);

    const partnerContextValue = React.useMemo(() => {
        return {
            partnerType: PartnerTypeEnum.broker,
            partnerId: DEFAULT_BROKER_COMPANY_ID,
        };
    }, []);

    const notificationBarContextValue = React.useMemo(() => {
        return {
            renderNotification,
            getNotificationLink,
        };
    }, []);

    const commonUrlFactoryContextValue = React.useMemo((): CommonUrlFactoryContextT => {
        return urlFactory;
    }, []);

    const featureContextValue = useFeaturesContextValue();

    return (
        <ErrorBoundary>
            <FeaturesContext.Provider value={featureContextValue}>
                <UiContext.Provider value={contextValue}>
                    <NotificationSidebarContext.Provider value={notificationBarContextValue}>
                        <PartnerContext.Provider value={partnerContextValue}>
                            <CommonUrlFactoryContext.Provider value={commonUrlFactoryContextValue}>
                                <Provider store={store}>
                                    <Router history={history}>
                                        <QueryParamProvider adapter={ReactRouter5Adapter}>
                                            <div className={cx('app')}>
                                                <AuthLoader>
                                                    <Switch>
                                                        <Route path={routesEnum.signIn}>
                                                            <SignInPage />
                                                        </Route>
                                                        <Route path={routesEnum.signUp}>
                                                            <SignUpPage />
                                                        </Route>
                                                        <Route path={routesEnum.continueSignUp}>
                                                            <ContinueSignUpPage />
                                                        </Route>
                                                        <Route path={routesEnum.forgotPassword}>
                                                            <ForgotPasswordPage />
                                                        </Route>
                                                        <Route path={routesEnum.changePassword}>
                                                            <ChangePasswordPage />
                                                        </Route>
                                                        <Route path={routesEnum.confirmEmail}>
                                                            <ConfirmEmailPage />
                                                        </Route>
                                                        <Route path={routesEnum.notFound}>
                                                            <NotFoundPage />
                                                        </Route>
                                                        <Route path="*">
                                                            <ClientPage />
                                                        </Route>
                                                    </Switch>
                                                </AuthLoader>
                                                <OverlaysManager
                                                    renderAlertToast={renderAlertToast}
                                                    renderNotificationToast={renderNotificationToast}
                                                    getNotificationLink={getNotificationLink}
                                                />
                                                <ErrorModal />
                                            </div>
                                        </QueryParamProvider>
                                    </Router>
                                </Provider>
                            </CommonUrlFactoryContext.Provider>
                        </PartnerContext.Provider>
                    </NotificationSidebarContext.Provider>
                </UiContext.Provider>
            </FeaturesContext.Provider>
        </ErrorBoundary>
    );
};

export default hot(module)(App);
