import React from 'react';

import classNames from 'classnames/bind';
import styles from './DataSourceDropdown.scss';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import isNil from 'lodash/isNil';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { useTranslation } from 'react-i18next';
import { StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';

const cx = classNames.bind(styles);

type ValueT = StatisticSourceEnum | null;

type PropsT = {
    selectedValue: ValueT;
    placeholder: string;
    isDisabled?: boolean;
    onSelect: (value: ValueT, label: string) => void;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    onReset?: () => void;
};

type OptionT = ValueT;

const OPTIONS = [StatisticSourceEnum.import, StatisticSourceEnum.native];

export const DATA_SOURCE_LABELS_T: Record<StatisticSourceEnum, string> = {
    [StatisticSourceEnum.import]: 'statistics.data-source.imported',
    [StatisticSourceEnum.native]: 'statistics.data-source.native',
};

const DataSourceDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        selectedValue,
        placeholder,
        onSelect,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        hasChanges,
        onBlur,
        onFocus,
        onReset,
    } = props;

    const { t } = useTranslation();

    const renderOption = (value?: ValueT, placeholder?: string): React.ReactNode => {
        if (isNil(value)) {
            return placeholder;
        }

        return t(DATA_SOURCE_LABELS_T[value]);
    };

    const getOptionValue = (option: OptionT): ValueT => option;

    const handleSelect = (value: ValueT) => {
        const label = value ? t(DATA_SOURCE_LABELS_T[value]) : '';

        onSelect(value, label);
    };

    return (
        <DropdownInput<OptionT, ValueT>
            className={className}
            selectedValue={selectedValue}
            placeholder={placeholder}
            options={OPTIONS}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasClearControl={!!onReset}
            onReset={onReset}
        />
    );
});

export default DataSourceDropdown;
