import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './AssignmentForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import AssignOptionLabel from './AssignOptionLabel/AssignOptionLabel';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import Receipt from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/AssignmentForm/Receipt/Receipt';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAssignmentRequestStatus,
    selectSelfCostPrediction,
    selectSelfCostPredictionRequestStatus,
    selectTrailerAssignmentInfoById,
    selectTrailerSuggestIds,
    selectTrailerSuggestRequestStatus,
    selectTruckAssignmentInfoById,
    selectTruckSuggestIds,
    selectTruckSuggestRequestStatus,
    selectUserSelection,
    selectVehicleLinks,
} from 'broker-admin/store/dispatch-assigment/selectors';
import {
    assign,
    changeUserSelection,
    findTrailer,
    findTruck,
    initUserSelection,
} from 'broker-admin/store/dispatch-assigment/actions';
import { getDateFromISO, MS_IN_MIN } from 'common/utils/time';
import {
    selectCarriersUtilizationByHash,
    selectFetchRequestStatus as selectCarriersUtilizationRequestStatus,
} from 'common/store/carriers-utilization/selectors';
import { getHash } from 'common/store/carriers-utilization/utils';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import asyncValidations from './async-validations';
import isEmpty from 'lodash/isEmpty';
import { PointToDropT, UserSelectionT } from 'broker-admin/store/dispatch-assigment/models';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import isEqual from 'lodash/isEqual';
import ButtonTimer from 'common/components/ButtonTimer/ButtonTimer';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { formatLocation } from 'common/utils/formatters';
import isNumber from 'lodash/isNumber';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import DropdownSearchInput, {
    IconMetaT,
} from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import AssignTriggerLabel from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/AssignmentForm/AssignTriggerLabel/AssignTriggerLabel';
import { AssetAssignmentInfoT } from 'common/utils/assignment';
import AssetLinkIcon, { AssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import { renderTrailerDropOffIcon, renderTruckDropOffIcon } from './icons';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DestinationIcon from 'common/icons/DestinationIcon';
import { useEqualPreviousValue } from 'common/utils/hooks/useEqualPreviousValue';
import mapValues from 'lodash/mapValues';
import { InitDropPointT } from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/AssignmentForm/models';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchId: DispatchIdT | null;
    dispatchDetails: DispatchDetailsT | null;
    onCancel: () => void;
};

type TrailerOptionT = AssetAssignmentInfoT & {
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
};

type TruckOptionT = AssetAssignmentInfoT & {
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
};

const convertLocationToPoint = (location: LocationT | null): PointToDropT | null => {
    if (!location || !location.point) {
        return null;
    }

    return {
        address: location.address || `${location.point?.lat},${location.point?.lng}`,
        addressComponents: location.addressComponents,
        point: location.point,
    };
};

const convertPointToLocation = (pointToDrop: PointToDropT | null): LocationT | null => {
    if (!pointToDrop || !pointToDrop?.point) {
        return null;
    }

    return {
        point: pointToDrop?.point || null,
        address: pointToDrop?.address || null,
        addressComponents: pointToDrop?.addressComponents || null,
        utcOffsetMinutes: null,
    };
};

const checkIsSameLocation = (
    locationA: LocationT | PointToDropT | null,
    locationB: LocationT | PointToDropT | null,
): boolean => {
    return isEqual(locationA?.point, locationB?.point);
};

const AssignmentForm: React.FC<PropsT> = (props) => {
    const { onCancel, dispatchId, dispatchDetails } = props;

    const { t } = useTranslation();

    const openLeftSidebar = useOpenLeftSidebar();

    const firstWaypointDateTimeFrom = dispatchDetails?.tours?.[0]?.waypoints?.[0].dateTimeFrom;
    const pickupDate = getDateFromISO(firstWaypointDateTimeFrom);

    const dispatch = useDispatch();

    const userSelection = useSelector(selectUserSelection);

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const selfCostPrediction = useSelector(selectSelfCostPrediction);
    const selfCostPredictionRequestStatus = useSelector(selectSelfCostPredictionRequestStatus);

    const vehicleLinks = useSelector(selectVehicleLinks);

    const truckSuggestRequestStatus = useSelector(selectTruckSuggestRequestStatus);
    const truckIds = useSelector(selectTruckSuggestIds);
    const truckById = useSelector(selectTruckAssignmentInfoById);

    const trailerSuggestRequestStatus = useSelector(selectTrailerSuggestRequestStatus);
    const trailerIds = useSelector(selectTrailerSuggestIds);
    const trailerById = useSelector(selectTrailerAssignmentInfoById);

    const carriersUtilizationFetchRequestStatus = useSelector(selectCarriersUtilizationRequestStatus);
    const carriersUtilizationByHash = useSelector(selectCarriersUtilizationByHash);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { trailerById, truckById });
    }, [t, trailerById, truckById]);

    const initDropPoint = useMemo((): InitDropPointT | null => {
        const waypoints = dispatchDetails?.tours?.[0]?.waypoints || [];
        const lastIndex = waypoints.length - 1;
        const lastPayloadWaypointAddress = waypoints[lastIndex]?.address || null;
        if (!lastPayloadWaypointAddress) {
            return null;
        }

        if (!isNumber(lastPayloadWaypointAddress?.latitude) || !isNumber(lastPayloadWaypointAddress?.longitude)) {
            return null;
        }

        return {
            location: {
                address: formatLocation(lastPayloadWaypointAddress),
                utcOffsetMinutes: null,
                addressComponents: {
                    countryCode: lastPayloadWaypointAddress.country,
                    street1: lastPayloadWaypointAddress?.street1,
                    street2: lastPayloadWaypointAddress?.street2,
                    zipCode: lastPayloadWaypointAddress?.zipCode,
                    city: lastPayloadWaypointAddress?.city,
                },
                point: {
                    lat: lastPayloadWaypointAddress.latitude || 0,
                    lng: lastPayloadWaypointAddress.longitude || 0,
                },
            },
            index: lastIndex,
        };
    }, [dispatchDetails]);

    const lazyInitDropPoint = useEqualPreviousValue(initDropPoint);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.trailerId]: null,
            [FieldsEnum.truckId]: null,
            [FieldsEnum.locationToDropTrailer]: lazyInitDropPoint?.location || null,
            [FieldsEnum.locationToDropTruck]: lazyInitDropPoint?.location || null,
        };

        const errors = validateForm(t, values, { trailerById, truckById });

        return [values, errors];
    }, [lazyInitDropPoint]);

    useEffect(() => {
        dispatch(
            initUserSelection({
                pointToDropTruck: convertLocationToPoint(initialValues[FieldsEnum.locationToDropTruck]),
                pointToDropTrailer: convertLocationToPoint(initialValues[FieldsEnum.locationToDropTrailer]),
            }),
        );
    }, [initialValues]);

    const assignmentContextId = selfCostPrediction?.assignmentContextId || null;

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!assignmentContextId) {
                logWarning(`Failed to assign, empty assignmentContextId`);
                return;
            }

            if (!dispatchId) {
                logWarning(`Failed to assign, empty dispatchId`);
                return;
            }

            dispatch(assign(dispatchId, assignmentContextId));

            formikHelpers.setTouched({});
        },
    });

    const asyncErrors = React.useMemo(() => {
        return asyncValidations(t, formik.values, selfCostPredictionRequestStatus);
    }, [t, formik.values, selfCostPredictionRequestStatus]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    const selectedTruckId = formik.values[FieldsEnum.truckId];
    const selectedTrailerId = formik.values[FieldsEnum.trailerId];
    useOnlyChangesEffect(() => {
        const changes: Partial<FormValuesT> = {};
        const touched: Partial<Record<FieldsEnum, boolean>> = {};
        if (selectedTrailerId !== userSelection.trailerId) {
            changes[FieldsEnum.trailerId] = userSelection.trailerId;
            touched[FieldsEnum.trailerId] = true;
        }
        if (selectedTruckId !== userSelection.truckId) {
            changes[FieldsEnum.truckId] = userSelection.truckId;
            touched[FieldsEnum.truckId] = true;
        }
        formik.setValues((prevValues) => ({
            ...prevValues,
            ...changes,
        }));
        formik.setTouched({
            ...touched,
        });
    }, [userSelection.truckId, userSelection.trailerId]);

    const selectedPointToDropTruck = formik.values[FieldsEnum.locationToDropTruck];
    const selectedPointToDropTrailer = formik.values[FieldsEnum.locationToDropTrailer];
    const isSelectedSameLocations = useMemo(() => {
        return checkIsSameLocation(selectedPointToDropTruck, selectedPointToDropTrailer);
    }, [selectedPointToDropTrailer, selectedPointToDropTruck]);

    useOnlyChangesEffect(() => {
        const changes: Partial<FormValuesT> = {};
        const touched: Partial<Record<FieldsEnum, boolean>> = {};

        if (!checkIsSameLocation(formik.values[FieldsEnum.locationToDropTruck], userSelection.pointToDropTruck)) {
            changes[FieldsEnum.locationToDropTruck] = convertPointToLocation(userSelection.pointToDropTruck);
            touched[FieldsEnum.locationToDropTruck] = true;
        }

        if (!checkIsSameLocation(formik.values[FieldsEnum.locationToDropTrailer], userSelection.pointToDropTrailer)) {
            changes[FieldsEnum.locationToDropTrailer] = convertPointToLocation(userSelection.pointToDropTrailer);
            touched[FieldsEnum.locationToDropTrailer] = true;

            if (isSelectedSameLocations) {
                changes[FieldsEnum.locationToDropTruck] = convertPointToLocation(userSelection.pointToDropTrailer);
                touched[FieldsEnum.locationToDropTruck] = true;
            }
        }

        formik.setValues((prevValues) => ({
            ...prevValues,
            ...changes,
        }));

        formik.setTouched({
            ...touched,
        });
    }, [userSelection.pointToDropTruck, userSelection.pointToDropTrailer]);

    const trailersOptions: TrailerOptionT[] = React.useMemo(() => {
        const ids = [...trailerIds];

        if (selectedTrailerId && !trailerIds.includes(selectedTrailerId)) {
            ids.unshift(selectedTrailerId);
        }
        const options = ids.reduce((result, id) => {
            const trailer = trailerById[id] || null;
            if (!trailer) {
                return result;
            }

            const carrierUtilizationHash = getHash(trailer.carrierId as string, pickupDate);

            const carrierUtilization = carriersUtilizationByHash[carrierUtilizationHash];

            result.push({
                ...trailer,
                carrierUtilizationPercent: carrierUtilization ? carrierUtilization.utilizationPercents : null,
                isCarrierUtilizationLoading: carriersUtilizationFetchRequestStatus.loading,
            });

            return result;
        }, [] as TrailerOptionT[]);

        return options;
    }, [
        trailerById,
        trailerIds,
        selectedTrailerId,
        pickupDate,
        carriersUtilizationFetchRequestStatus,
        carriersUtilizationByHash,
    ]);

    const trucksOptions: TruckOptionT[] = React.useMemo(() => {
        const ids = [...truckIds];

        if (selectedTruckId && !truckIds.includes(selectedTruckId)) {
            ids.unshift(selectedTruckId);
        }

        const options = ids.reduce((options, id) => {
            const truck = truckById[id] || null;
            if (!truck) {
                return options;
            }

            const carrierUtilizationHash = getHash(truck.carrierId as string, pickupDate);

            const carrierUtilization = carriersUtilizationByHash[carrierUtilizationHash];

            options.push({
                ...truck,
                carrierUtilizationPercent: carrierUtilization ? carrierUtilization.utilizationPercents : null,
                isCarrierUtilizationLoading: carriersUtilizationFetchRequestStatus.loading,
            });

            return options;
        }, [] as TruckOptionT[]);

        return options;
    }, [
        truckById,
        truckIds,
        selectedTruckId,
        pickupDate,
        carriersUtilizationFetchRequestStatus,
        carriersUtilizationByHash,
    ]);

    const getOptionValue = (option: TruckOptionT | TrailerOptionT) => option?.id;

    const renderOption = (option: TruckOptionT | TrailerOptionT) => {
        return (
            <AssignOptionLabel
                isInvalid={!option?.valid}
                model={option.model}
                plateNumber={option.plateNumber}
                price={t('assignment.rate', { rate: option.ratePerKm })}
                isAssetUtilizationLoading={false}
                assetUtilizationPercent={option.utilization}
                carrierUtilizationPercent={option.carrierUtilizationPercent}
                isCarrierUtilizationLoading={option.isCarrierUtilizationLoading}
            />
        );
    };

    const handleSelectTruck = (truckId: TruckIdT | null) => {
        const linkedTrailerId = vehicleLinks.byTruckId[truckId as TruckIdT];

        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.truckId]: truckId,
        };
        if (linkedTrailerId) {
            formValueChanges[FieldsEnum.trailerId] = linkedTrailerId;
        }
        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched(mapValues(formValueChanges, () => true));
        }, 0);

        const changes: Partial<UserSelectionT> = { truckId };
        if (linkedTrailerId) {
            changes.trailerId = linkedTrailerId;
        }

        dispatch(changeUserSelection(dispatchId, changes));
    };

    const renderTruckTriggerIcon = (selectedTruckId: TruckIdT | null) => (iconMeta: IconMetaT) => {
        const isLinked = !!selectedTruckId && !!vehicleLinks.byTruckId[selectedTruckId];
        if (isLinked) {
            return <AssetLinkIcon {...AssetLinkIconProps.getControlProps(iconMeta)} />;
        }

        return <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />;
    };

    const renderTruckTrigger = (option: TruckOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        return (
            <AssignTriggerLabel
                model={option?.model}
                plateNumber={option?.plateNumber}
                rate={option?.ratePerKm}
                onReset={() => {
                    handleSelectTruck(null);
                }}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.truckDetails,
                            truckId: option?.id,
                            partnerId: option?.carrierId || '',
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    const handleSelectTrailer = (trailerId: TrailerIdT | null) => {
        const linkedTruckId = vehicleLinks.byTrailerId[trailerId as TrailerIdT];

        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.trailerId]: trailerId,
        };
        if (linkedTruckId) {
            formValueChanges[FieldsEnum.truckId] = linkedTruckId;
        }
        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched(mapValues(formValueChanges, () => true));
        }, 0);

        const changes: Partial<UserSelectionT> = { trailerId };
        if (linkedTruckId) {
            changes.truckId = linkedTruckId;
        }

        dispatch(changeUserSelection(dispatchId, changes));
    };

    const renderTrailerTriggerIcon = (selectedTrailerId: TrailerIdT | null) => (iconMeta: IconMetaT) => {
        const isLinked = !!selectedTrailerId && !!vehicleLinks.byTrailerId[selectedTrailerId];
        if (isLinked) {
            return <AssetLinkIcon {...AssetLinkIconProps.getControlProps(iconMeta)} />;
        }

        return <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />;
    };

    const renderTrailerTrigger = (option: TrailerOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        return (
            <AssignTriggerLabel
                model={option?.model}
                plateNumber={option?.plateNumber}
                rate={option?.ratePerKm}
                onReset={() => {
                    handleSelectTrailer(null);
                }}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.trailerDetails,
                            trailerId: option?.id,
                            partnerId: option?.carrierId || '',
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    const handleSelectPointToDropTruck = (value: LocationT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.locationToDropTruck]: value,
        };

        const userSelectionChanges: Partial<UserSelectionT> = {
            pointToDropTruck: convertLocationToPoint(value),
        };

        formik.setValues((formValues) => ({ ...formValues, ...formValueChanges }));

        dispatch(changeUserSelection(dispatchId, userSelectionChanges));
    };

    const handleSelectPointToDropTrailer = (value: LocationT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.locationToDropTrailer]: value,
        };
        const userSelectionChanges: Partial<UserSelectionT> = {
            pointToDropTrailer: convertLocationToPoint(value),
        };

        if (isSelectedSameLocations) {
            formValueChanges[FieldsEnum.locationToDropTruck] = value;
            userSelectionChanges.pointToDropTruck = convertLocationToPoint(value);
        }

        formik.setValues((formValues) => ({
            ...formValues,
            ...formValueChanges,
        }));

        dispatch(changeUserSelection(dispatchId, userSelectionChanges));
    };

    const handleExpire = () => {
        dispatch(changeUserSelection(dispatchId, {}));
    };

    const hasAsyncErros = !isEmpty(asyncErrors);

    const isLoadingSubmit = selfCostPredictionRequestStatus.loading || assignmentRequestStatus.loading;
    const isDisabledSubmit = isLoadingSubmit || hasAsyncErros;

    return (
        <div className={cx('wrap')}>
            <Receipt
                className={cx('receipt')}
                dispatchDetails={dispatchDetails}
                isNotActual={!formik.values[FieldsEnum.truckId] || !formik.values[FieldsEnum.trailerId]}
                selectedTruckId={formik.values[FieldsEnum.truckId]}
                selectedTrailerId={formik.values[FieldsEnum.trailerId]}
            />
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('form')}>
                    <FormikField
                        name={FieldsEnum.truckId}
                        error={formik.errors[FieldsEnum.truckId]}
                        meta={formik.getFieldMeta(FieldsEnum.truckId)}
                        label={t('assignment.fields.truck.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        asyncError={asyncFormErrors[FieldsEnum.truckId]}
                        resetAsyncError={resetAsyncFormErrors}
                    >
                        {(props) => (
                            <DropdownSearchInput<TruckOptionT, TruckIdT | null>
                                selectedValue={formik.values[FieldsEnum.truckId]}
                                options={trucksOptions}
                                onSelect={handleSelectTruck}
                                renderTrigger={renderTruckTrigger}
                                renderOption={renderOption}
                                getOptionValue={getOptionValue}
                                isLoading={truckSuggestRequestStatus.loading}
                                onChangeQuery={(text) => {
                                    if (!dispatchId) {
                                        return;
                                    }

                                    dispatch(
                                        findTruck({
                                            dispatchId,
                                            plateNumber: text,
                                            trailerId: formik.values[FieldsEnum.trailerId],
                                            isIgnoreCertificates: userSelection.isIgnoreCertificates,
                                            isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                                            pointToDropTrailer: userSelection.pointToDropTrailer,
                                            pointToDropTruck: userSelection.pointToDropTruck,
                                        }),
                                    );
                                }}
                                renderLeftIcon={renderTruckTriggerIcon(selectedTruckId)}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                inputPlaceholder={t('assignment.fields.truck.input-placeholder')}
                                placeholder={t('assignment.fields.truck.placeholder')}
                                hasOptionsSeparator
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.trailerId}
                        error={formik.errors[FieldsEnum.trailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.trailerId)}
                        label={t('assignment.fields.trailer.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        asyncError={asyncFormErrors[FieldsEnum.trailerId]}
                        resetAsyncError={resetAsyncFormErrors}
                    >
                        {(props) => (
                            <DropdownSearchInput<TrailerOptionT, TrailerIdT | null>
                                selectedValue={formik.values[FieldsEnum.trailerId]}
                                options={trailersOptions}
                                onSelect={handleSelectTrailer}
                                renderTrigger={renderTrailerTrigger}
                                renderOption={renderOption}
                                isLoading={trailerSuggestRequestStatus.loading}
                                getOptionValue={getOptionValue}
                                onChangeQuery={(text) => {
                                    if (!dispatchId) {
                                        return;
                                    }

                                    dispatch(
                                        findTrailer({
                                            dispatchId,
                                            plateNumber: text,
                                            truckId: formik.values[FieldsEnum.truckId],
                                            isIgnoreCertificates: userSelection.isIgnoreCertificates,
                                            isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                                            pointToDropTrailer: userSelection.pointToDropTrailer,
                                            pointToDropTruck: userSelection.pointToDropTruck,
                                        }),
                                    );
                                }}
                                renderLeftIcon={renderTrailerTriggerIcon(selectedTrailerId)}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                inputPlaceholder={t('assignment.fields.trailer.input-placeholder')}
                                placeholder={t('assignment.fields.trailer.placeholder')}
                                hasOptionsSeparator
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.locationToDropTrailer}
                        error={formik.errors[FieldsEnum.locationToDropTrailer]}
                        meta={formik.getFieldMeta(FieldsEnum.locationToDropTrailer)}
                        label={t('assignment.fields.point-to-drop-trailer.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <GeoSuggest
                                name={FieldsEnum.locationToDropTrailer}
                                value={formik.values[FieldsEnum.locationToDropTrailer]}
                                placeholder={t('assignment.fields.point-to-drop-trailer.placeholder')}
                                renderLeftIcon={renderTrailerDropOffIcon(
                                    formik.values[FieldsEnum.locationToDropTrailer]
                                        ? 'assignment-input-selected'
                                        : 'assignment-input-normal',
                                    initDropPoint,
                                    formik.values[FieldsEnum.locationToDropTruck],
                                    formik.values[FieldsEnum.locationToDropTrailer],
                                )}
                                onChange={handleSelectPointToDropTrailer}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                testSelector="point-to-drop-trailer"
                                showClearControl={!!formik.values[FieldsEnum.locationToDropTrailer]}
                                overlayPosition="top"
                                renderRightNode={() =>
                                    !checkIsSameLocation(
                                        initDropPoint?.location || null,
                                        formik.values[FieldsEnum.locationToDropTrailer],
                                    ) && (
                                        <TransparentTrigger
                                            spaces="xs"
                                            onClick={() => {
                                                handleSelectPointToDropTrailer(initDropPoint?.location || null);
                                            }}
                                            leftIcon={<DestinationIcon />}
                                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                        />
                                    )
                                }
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.locationToDropTruck}
                        error={formik.errors[FieldsEnum.locationToDropTruck]}
                        meta={formik.getFieldMeta(FieldsEnum.locationToDropTruck)}
                        label={t('assignment.fields.point-to-drop-truck.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <GeoSuggest
                                name={FieldsEnum.locationToDropTruck}
                                value={formik.values[FieldsEnum.locationToDropTruck]}
                                placeholder={t('assignment.fields.point-to-drop-truck.placeholder')}
                                renderLeftIcon={renderTruckDropOffIcon(
                                    formik.values[FieldsEnum.locationToDropTruck]
                                        ? 'assignment-input-selected'
                                        : 'assignment-input-normal',
                                    initDropPoint,
                                    formik.values[FieldsEnum.locationToDropTruck],
                                    formik.values[FieldsEnum.locationToDropTrailer],
                                )}
                                onChange={handleSelectPointToDropTruck}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                testSelector="point-to-drop-truck"
                                showClearControl={!!formik.values[FieldsEnum.locationToDropTruck]}
                                overlayPosition="top"
                                renderRightNode={() =>
                                    !checkIsSameLocation(
                                        initDropPoint?.location || null,
                                        formik.values[FieldsEnum.locationToDropTruck],
                                    ) && (
                                        <TransparentTrigger
                                            spaces="xs"
                                            onClick={() => {
                                                handleSelectPointToDropTruck(initDropPoint?.location || null);
                                            }}
                                            leftIcon={<DestinationIcon />}
                                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                        />
                                    )
                                }
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('actions')}>
                    <Button
                        theme={ButtonThemeEnum.secondary}
                        className={cx('action', 'action--cancel')}
                        onClick={onCancel}
                    >
                        {t('assignment.actions.cancel')}
                    </Button>
                    <Button
                        isFullWidth
                        theme={ButtonThemeEnum.primary}
                        isDisabled={isDisabledSubmit}
                        className={cx('action', 'action--assign')}
                        isLoading={isLoadingSubmit}
                        type="submit"
                        leftIcon={<div />}
                        rightIcon={
                            <ButtonTimer
                                showTime={MS_IN_MIN}
                                expireTime={selfCostPrediction?.expireTime || null}
                                tooltipMessage={t('assignment.time-left-to-accept-cost')}
                                onExpire={handleExpire}
                            />
                        }
                    >
                        {t('assignment.actions.assign')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AssignmentForm;
