import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerDocumentsPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    ApiCarrierContractStatusT,
    ApiShipperContractStatusT,
    CarrierContractStatusEnum,
    DocumentStatusEnum,
    PartnerTypeEnum,
    ShipperContractStatusEnum,
} from 'common/utils/api/models';
import { fetchDocumentsDict } from 'common/store/documents-dict/actions';
import { selectDocumentsDictRequest } from 'common/store/documents-dict/selectors';
import { fetchDocuments } from 'common/store/documents/actions';
import { selectPermissions } from 'common/store/auth/selectors';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import { useTranslation } from 'react-i18next';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { useDocumentsRows } from 'common/layouts/BaseDocumentsLayout/hooks/use-documents-rows';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import history from 'common/utils/history';
import { Redirect, Route, Switch } from 'react-router-dom';
import BaseDocumentsLayout from 'common/layouts/BaseDocumentsLayout/BaseDocumentsLayout';
import { selectFetchCompanyDocumentsRequest } from 'common/store/documents/selectors';
import { urlFactory } from 'broker-admin/utils/urls';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import BaseCarrierContractsLayout from 'common/layouts/BaseCarrierContractsLayout/BaseCarrierContractsLayout';
import { selectCarrierContracts } from 'common/store/carrier-contracts/selectors';
import BaseShipperContractsLayout from 'common/layouts/BaseShipperContractsLayout/BaseShipperContractsLayout';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import isString from 'lodash/isString';
import { selectPartnerDetailsById } from 'broker-admin/store/partner/details/selectors';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { partnerDetailsRefreshChannel } from 'broker-admin/store/partner/details/channels';

const cx = classNames.bind(styles);

type PropsT = {};

const PartnerDocumentsPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const partnerDetailsById = useSelector(selectPartnerDetailsById);
    const partnerDetails = partnerDetailsById[partnerId];

    const { t } = useTranslation();

    const openLeftSidebar = useOpenLeftSidebar();

    const permissions = useSelector(selectPermissions);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId));
    }, [partnerType, partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId, { isForceUpdate: false }));
    }, [partnerType, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchDocumentsDict(partnerType));
        dispatch(fetchDocuments(partnerId, { isForceUpdate: true }));
    }, [partnerType, partnerId]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshPageHandler);
    useChannelSubscribe(partnerDetailsRefreshChannel, refreshPageHandler);

    const fetchDocumentsDictRequest = useSelector(selectDocumentsDictRequest(partnerType));
    const fetchRequest = useSelector(selectFetchCompanyDocumentsRequest(partnerId));

    const isLoading = fetchRequest.loading || fetchDocumentsDictRequest.loading;

    const { requiredDocumentRows, optionalDocumentRows } = useDocumentsRows(partnerId, partnerType);

    const requiredDocumentStatusesAttentionSet = useMemo(() => {
        const statuses: Array<DocumentStatusEnum | null> = [
            null, // not uploaded
            DocumentStatusEnum.expired,
            DocumentStatusEnum.revoked,
            DocumentStatusEnum.rejected,
        ];

        if (permissions?.canReviewDocuments) {
            statuses.push(DocumentStatusEnum.waitingForApprove);
        }

        return new Set<DocumentStatusEnum | null>(statuses);
    }, [permissions]);

    const optionalDocumentStatusesAttentionSet = useMemo(() => {
        const statuses: Array<DocumentStatusEnum | null> = [];

        if (permissions?.canReviewDocuments) {
            statuses.push(DocumentStatusEnum.waitingForApprove);
        }

        return new Set<DocumentStatusEnum | null>(statuses);
    }, [permissions]);

    const carrierContactStatusesAttentionSet = useMemo(() => {
        const statuses: Array<CarrierContractStatusEnum | null> = [];

        if (permissions?.canReviewDocuments) {
            statuses.push(CarrierContractStatusEnum.waitingForApprove);
        }

        return new Set<ApiCarrierContractStatusT | null>(statuses);
    }, [permissions]);

    const shipperContactStatusesAttentionSet = useMemo(() => {
        const statuses: Array<ShipperContractStatusEnum | null> = [];

        return new Set<ApiShipperContractStatusT | null>(statuses);
    }, [permissions]);

    const badgeRequiredDocumentsCount = React.useMemo(() => {
        return requiredDocumentRows?.filter((row) => {
            return (
                !row?.actualVersion ||
                requiredDocumentStatusesAttentionSet.has(row?.actualVersion?.status as DocumentStatusEnum)
            );
        }).length;
    }, [requiredDocumentRows, requiredDocumentStatusesAttentionSet]);

    const badgeOptionalDocumentsCount = React.useMemo(() => {
        return optionalDocumentRows?.filter((row) => {
            return optionalDocumentStatusesAttentionSet.has(row?.actualVersion?.status as DocumentStatusEnum);
        }).length;
    }, [optionalDocumentRows, optionalDocumentStatusesAttentionSet]);

    const carrierContracts = useSelector(selectCarrierContracts(partnerId));
    const badgeCarrierContractsCount = React.useMemo(() => {
        return carrierContracts?.filter((contract) => {
            return contract.status && carrierContactStatusesAttentionSet.has(contract.status);
        }).length;
    }, [carrierContracts, carrierContactStatusesAttentionSet]);

    const badgeShipperContractsCount = React.useMemo(() => {
        return 0;
    }, []);

    const requiredPartnerDocumentsPath = urlFactory.requiredPartnerDocuments(partnerId, partnerType);
    const optionalPartnerDocumentsPath = urlFactory.optionalPartnerDocuments(partnerId, partnerType);
    const partnerCarrierContractsPath = urlFactory.partnerCarrierContacts(partnerId, partnerType);
    const partnerShipperContractsPath = urlFactory.partnerShipperContacts(partnerId, partnerType);

    const tabsConfig = React.useMemo((): Array<NavigationTabConfigT | null> => {
        const tabs: Array<NavigationTabConfigT> = [
            {
                pathname: requiredPartnerDocumentsPath,
                to: requiredPartnerDocumentsPath,
                badge: badgeRequiredDocumentsCount ? (
                    <NotificationLabel count={badgeRequiredDocumentsCount} theme={NotificationLabelThemeEnum.orange} />
                ) : null,
                render: () => t('common:profile-page.documents-tabs.required'),
                testSelector: 'required-documents',
            },
            {
                pathname: optionalPartnerDocumentsPath,
                to: optionalPartnerDocumentsPath,
                badge: badgeOptionalDocumentsCount ? (
                    <NotificationLabel count={badgeOptionalDocumentsCount} theme={NotificationLabelThemeEnum.orange} />
                ) : null,
                render: () => t('common:profile-page.documents-tabs.optional'),
                testSelector: 'optional-documents',
            },
        ];

        if (partnerType === PartnerTypeEnum.carrier) {
            tabs.push({
                pathname: partnerCarrierContractsPath,
                to: partnerCarrierContractsPath,
                badge: badgeCarrierContractsCount ? (
                    <NotificationLabel count={badgeCarrierContractsCount} theme={NotificationLabelThemeEnum.orange} />
                ) : null,
                render: () => t('common:profile-page.documents-tabs.contracts'),
                testSelector: 'carrier-contracts',
            });
        }

        if (partnerType === PartnerTypeEnum.shipper) {
            tabs.push({
                pathname: partnerShipperContractsPath,
                to: partnerShipperContractsPath,
                badge: badgeShipperContractsCount ? (
                    <NotificationLabel count={badgeShipperContractsCount} theme={NotificationLabelThemeEnum.orange} />
                ) : null,
                render: () => t('common:profile-page.documents-tabs.contracts'),
                testSelector: 'shipper-contracts',
            });
        }

        return tabs;
    }, [
        partnerType,
        requiredPartnerDocumentsPath,
        optionalPartnerDocumentsPath,
        partnerCarrierContractsPath,
        partnerShipperContractsPath,
        badgeRequiredDocumentsCount,
        badgeOptionalDocumentsCount,
        badgeCarrierContractsCount,
        badgeShipperContractsCount,
        t,
    ]);

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        history.push({
            ...(isString(to) ? { pathname: to } : to),
            search: history.location.search,
        });
    };

    const handleUploadShipperContract = () => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.uploadShipperContract,
            partnerId,
        });
    };

    const isAllDocumentsVerified = !!partnerDetails?.documentsVerified;

    const navigationTabs = <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />;

    const handleOpenShipperContractDetails = (shipperContractId: ShipperContractIdT) => {
        const url = urlFactory.partnerShipperContactLanes({
            partnerId,
            partnerType,
            contractId: shipperContractId,
        });

        history.push(url);
    };

    return (
        <ContentMargins>
            <div className={cx('wrap')}>
                <div className={cx('document-table-wrap')}>
                    <Switch>
                        <Route path={requiredPartnerDocumentsPath}>
                            <BaseDocumentsLayout
                                navigationTabs={navigationTabs}
                                tableName="required-documents"
                                testSelector="required-documents"
                                isAllDocumentsVerified={isAllDocumentsVerified}
                                isShowReviewUser
                                isLoading={isLoading}
                                rows={requiredDocumentRows}
                                documentStatusesAttentionSet={requiredDocumentStatusesAttentionSet}
                            />
                        </Route>
                        <Route path={optionalPartnerDocumentsPath}>
                            <BaseDocumentsLayout
                                navigationTabs={navigationTabs}
                                tableName="optional-documents"
                                testSelector="optional-documents"
                                isAllDocumentsVerified={isAllDocumentsVerified}
                                isShowReviewUser
                                isLoading={isLoading}
                                rows={optionalDocumentRows}
                                documentStatusesAttentionSet={optionalDocumentStatusesAttentionSet}
                            />
                        </Route>
                        <Route path={partnerCarrierContractsPath}>
                            <BaseCarrierContractsLayout
                                navigationTabs={navigationTabs}
                                tableName="carrier-contracts"
                                isLoading={isLoading}
                                testSelector="carrier-contracts"
                                contactStatusesAttentionSet={carrierContactStatusesAttentionSet}
                            />
                        </Route>
                        <Route path={partnerShipperContractsPath}>
                            <BaseShipperContractsLayout
                                navigationTabs={navigationTabs}
                                contactStatusesAttentionSet={shipperContactStatusesAttentionSet}
                                onUploadShipperContract={handleUploadShipperContract}
                                onOpenShipperContractDetails={handleOpenShipperContractDetails}
                            />
                        </Route>
                        <Route>
                            <Redirect to={urlFactory.requiredPartnerDocuments(partnerId, partnerType)} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </ContentMargins>
    );
});

export default PartnerDocumentsPage;
