import { QueryFiltersT } from 'common/models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchDispatchesPageQueryT } from 'broker-admin/store/dispatches/models';
import { DispatchesQuickFilterEnum, DispatchesSortEnum } from 'common/utils/api/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { PageSortT } from 'common/utils/query';

const getQueryFromQuickFilters = (quickFilter?: DispatchesQuickFilterEnum): FetchDispatchesPageQueryT => {
    switch (quickFilter) {
        case DispatchesQuickFilterEnum.carrierUnassigned: {
            return {
                statusBar: 'UNASSIGNED',
            };
        }
        case DispatchesQuickFilterEnum.runningOutOfTime: {
            return {
                statusBar: 'RUNNING_OOT_TO_ASSIGN',
            };
        }
        case DispatchesQuickFilterEnum.ranOutOfTime: {
            return {
                statusBar: 'RUN_OUT_OF_TIME_TO_ASSIGN',
            };
        }
        case DispatchesQuickFilterEnum.shipmentExpired: {
            return {
                statusBar: 'NO_SHOW',
            };
        }
        case DispatchesQuickFilterEnum.assigned: {
            return {
                statusBar: 'ASSIGNED',
            };
        }
        case DispatchesQuickFilterEnum.inTransit: {
            return {
                statusBar: 'IN_TRANSIT',
            };
        }
        case DispatchesQuickFilterEnum.delaying: {
            return {
                statusBar: 'DELAYING',
            };
        }
        case DispatchesQuickFilterEnum.delivered: {
            return {
                statusBar: 'DELIVERED',
            };
        }
        case DispatchesQuickFilterEnum.canceled: {
            return {
                statusBar: 'CANCELED',
            };
        }
        case DispatchesQuickFilterEnum.shipmentMissed: {
            return {
                statusBar: 'SHIPMENT_MISSED',
            };
        }
        case DispatchesQuickFilterEnum.shipmentCanceled: {
            return {
                statusBar: 'SHIPMENT_CANCELED',
            };
        }
        case DispatchesQuickFilterEnum.declined: {
            return {
                statusBar: 'DECLINED',
            };
        }
        default: {
            return {};
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<DispatchesSortEnum> | null,
): FetchDispatchesPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

    return {
        carrierId: queryFilters[QueryFiltersKeysEnum.carrierId],
        shipperId: queryFilters[QueryFiltersKeysEnum.shipperId],

        dispatcherId: queryFilters[QueryFiltersKeysEnum.dispatcherId],
        keyAccountManagerId: queryFilters[QueryFiltersKeysEnum.keyAccountManagerId],

        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,

        address: queryFilters[QueryFiltersKeysEnum.address] || '',

        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom],
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo],

        dropOffDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom],
        dropOffDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo],

        pickupDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom],
        pickupDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo],

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText],

        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
