import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestRoute.scss';
import { useSelector } from 'react-redux';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useMemo } from 'react';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import MultiPointOrderRouteMap, {
    MultiPointOrderRouteMapPropsT,
} from 'common/components/MultiPointOrderRouteMap/MultiPointOrderRouteMap';

import { StopEnum } from 'common/utils/api/models';
import { selectDispatchDetails } from 'broker-admin/store/dispatch-details/selectors';

type PropsT = {
    id: SpotRequestIdT;
};

const cx = classNames.bind(styles);

const SpotRequestRoute: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    const { details } = useSelector(selectSpotRequestDetails(id));

    const dispatchId = details?.dispatchId || null;
    const dispatchDetails = useSelector(selectDispatchDetails(dispatchId || null));

    const polylineIds = useMemo(() => {
        return [details?.polylineId].filter(isNonNil);
    }, [details?.polylineId]);

    useRouteGeometry(polylineIds);

    const routingGeometryState = useSelector(selectRoutingGeometryState(details?.polylineId));

    const mapPoints = useMemo((): MultiPointOrderRouteMapPropsT['points'] => {
        return (
            dispatchDetails?.tours?.[0]?.waypoints?.map((waypoint) => {
                return {
                    latitude: waypoint?.address?.latitude,
                    longitude: waypoint?.address?.longitude,
                    index: waypoint.index || 0,
                    driveThru: waypoint.type === StopEnum.driveThrough,
                };
            }) || []
        );
    }, [dispatchDetails]);

    return (
        <MultiPointOrderRouteMap
            key={details?.id}
            points={mapPoints}
            route={routingGeometryState.data}
            routeRequest={routingGeometryState.requestStatus}
        />
    );
});

export default SpotRequestRoute;
