import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './NewOrderParametersSettingsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import prepareApiSystemSettings from './prepare-system-settings';
import NumberInput from 'common/components/NumberInput/NumberInput';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { ALL_DAY_RANGE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Widget from 'common/components/Widget/Widget';
import values from 'lodash/values';
import { getMaskByType } from '../utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { getTimeRelativeStartDay, MS_IN_HOUR } from 'common/utils/time';
import RangeSelector, {
    RangeSelectorLabelsConfigT,
    RangeSelectorRangeT,
} from 'common/components/RangeSelector/RangeSelector';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import TimeIntervalDurationPillLabel from 'common/layouts/NewOrderPage/ShipmentDetailsForm/TimeIntervalDurationPillLabel/TimeIntervalDurationPillLabel';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { SystemSettingsFormCommonPropsT } from 'broker-admin/layouts/SettingsPage/SystemSettings/models';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';

const cx = classNames.bind(styles);

const UNIT_TYPE_MAP = {
    [FieldsEnum.urgentOrderProcessingDelay]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.regularOrderProcessingDelay]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.minWindowSize]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.maxWindowSize]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.timeSlotsDelegationLimit]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.orderCreationSeconds]: UnitTypeEnum.secondsAbbreviation,
    [FieldsEnum.mapPrecisionDelta]: UnitTypeEnum.metersAbbreviation,
};

const STEP_MAP = {
    [FieldsEnum.urgentOrderProcessingDelay]: 1,
    [FieldsEnum.regularOrderProcessingDelay]: 1,
    [FieldsEnum.minWindowSize]: 30,
    [FieldsEnum.maxWindowSize]: 30,
    [FieldsEnum.timeSlotsDelegationLimit]: 1,
    [FieldsEnum.orderCreationSeconds]: 1,
    [FieldsEnum.mapPrecisionDelta]: 50,
};

const FIELD_LABEL_T_MAP = {
    [FieldsEnum.urgentOrderProcessingDelay]:
        'price-settings.new-order-parameters-form.fields.urgentOrderProcessingDelay.label',
    [FieldsEnum.regularOrderProcessingDelay]:
        'price-settings.new-order-parameters-form.fields.regularOrderProcessingDelay.label',
    [FieldsEnum.minWindowSize]: 'price-settings.new-order-parameters-form.fields.minWindowSize.label',
    [FieldsEnum.maxWindowSize]: 'price-settings.new-order-parameters-form.fields.maxWindowSize.label',
    [FieldsEnum.brokerWorkingHours]: 'price-settings.new-order-parameters-form.fields.brokerWorkingHours.label',
    [FieldsEnum.timeSlotsDelegationLimit]:
        'price-settings.new-order-parameters-form.fields.timeSlotsDelegationLimit.label',
    [FieldsEnum.orderCreationSeconds]: 'price-settings.new-order-parameters-form.fields.orderCreationSeconds.label',
    [FieldsEnum.quantumMinutes]: 'price-settings.new-order-parameters-form.fields.quantumMinutes.label',
    [FieldsEnum.mapPrecisionDelta]: 'price-settings.new-order-parameters-form.fields.mapPrecisionDelta.label',
};

const FIELD_DESCRIPTION_T_MAP = {
    [FieldsEnum.urgentOrderProcessingDelay]:
        'price-settings.new-order-parameters-form.fields.urgentOrderProcessingDelay.description',
    [FieldsEnum.regularOrderProcessingDelay]:
        'price-settings.new-order-parameters-form.fields.regularOrderProcessingDelay.description',
    [FieldsEnum.minWindowSize]: 'price-settings.new-order-parameters-form.fields.minWindowSize.description',
    [FieldsEnum.maxWindowSize]: 'price-settings.new-order-parameters-form.fields.maxWindowSize.description',
    [FieldsEnum.brokerWorkingHours]: 'price-settings.new-order-parameters-form.fields.brokerWorkingHours.description',
    [FieldsEnum.timeSlotsDelegationLimit]:
        'price-settings.new-order-parameters-form.fields.timeSlotsDelegationLimit.description',
    [FieldsEnum.orderCreationSeconds]:
        'price-settings.new-order-parameters-form.fields.orderCreationSeconds.description',
    [FieldsEnum.quantumMinutes]: 'price-settings.new-order-parameters-form.fields.quantumMinutes.description',
    [FieldsEnum.mapPrecisionDelta]: 'price-settings.new-order-parameters-form.fields.mapPrecisionDelta.description',
};

type PropsT = SystemSettingsFormCommonPropsT;

type QuantumT = '15' | '30' | '60';
type QuantumOptionT = {
    label: React.ReactNode;
    value: QuantumT;
};

const quantumAvailableValues: QuantumT[] = ['15', '30', '60'];

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type NumberFieldsT =
    | FieldsEnum.minWindowSize
    | FieldsEnum.maxWindowSize
    | FieldsEnum.timeSlotsDelegationLimit
    | FieldsEnum.orderCreationSeconds
    | FieldsEnum.urgentOrderProcessingDelay
    | FieldsEnum.regularOrderProcessingDelay
    | FieldsEnum.mapPrecisionDelta;

const BROKER_WORKING_HOURS_RANGE = ALL_DAY_RANGE;
const BROKER_WORKING_HOURS_RANGES: Array<RangeSelectorRangeT> = [];

const labelsConfig: RangeSelectorLabelsConfigT = {
    shownLabelCount: 6,
    shownStepCount: 2,
    hasStartStep: true,
    hasEndStep: true,
};

const NewOrderParametersSettingsForm: React.FC<PropsT> = (props) => {
    const { systemSettings, isLoading, title, onUpdate } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(systemSettings);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [systemSettings]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const apiSystemSettings = prepareApiSystemSettings(values);
            onUpdate(apiSystemSettings);

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const renderNumberField = (name: NumberFieldsT): React.ReactNode => {
        return (
            <FormikField
                className={cx('field')}
                name={name}
                error={formik.errors[name]}
                meta={formik.getFieldMeta(name)}
                label={t(FIELD_LABEL_T_MAP[name])}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t(FIELD_DESCRIPTION_T_MAP[name])}
                    </TooltipContent>
                }
            >
                {(props) => (
                    <NumberInput
                        name={name}
                        unitType={UNIT_TYPE_MAP[name]}
                        mask={getMaskByType(UNIT_TYPE_MAP[name])}
                        step={STEP_MAP[name]}
                        value={formik.values[name]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        hasChanges={props.hasChanges}
                    />
                )}
            </FormikField>
        );
    };

    const quantumOptions: QuantumOptionT[] = quantumAvailableValues.map((value) => {
        return {
            label: <UnitTypeCount count={+value} type={UnitTypeEnum.minutesAbbreviation} />,
            value,
        };
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Widget className={cx('widget')} title={title} rightNode={<WidgetChangesDetector fields={ALL_FIELDS} />}>
                <div className={cx('widget__inner')}>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.minWindowSize)}
                        {renderNumberField(FieldsEnum.maxWindowSize)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.urgentOrderProcessingDelay)}
                        {renderNumberField(FieldsEnum.regularOrderProcessingDelay)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.timeSlotsDelegationLimit)}
                        <FieldGroupEmptyItem className={cx('field')} />
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.orderCreationSeconds)}
                        <FormikField
                            className={cx('field')}
                            label={t(FIELD_LABEL_T_MAP[FieldsEnum.quantumMinutes])}
                            name={FieldsEnum.quantumMinutes}
                            error={formik.errors[FieldsEnum.quantumMinutes]}
                            meta={formik.getFieldMeta(FieldsEnum.quantumMinutes)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                                    {t(FIELD_DESCRIPTION_T_MAP[FieldsEnum.quantumMinutes])}
                                </TooltipContent>
                            }
                        >
                            {(props) => (
                                <DropdownInput<QuantumOptionT, QuantumT>
                                    selectedValue={formik.values[FieldsEnum.quantumMinutes] as QuantumT}
                                    options={quantumOptions}
                                    onSelect={props.onChange}
                                    renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                    getOptionValue={(option) => option.value}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.mapPrecisionDelta)}
                        <FieldGroupEmptyItem className={cx('field')} />
                    </FieldGroup>
                    <FormikField
                        className={cx('field', 'field--range')}
                        name={FieldsEnum.brokerWorkingHours}
                        error={formik.errors[FieldsEnum.brokerWorkingHours]}
                        meta={formik.getFieldMeta(FieldsEnum.brokerWorkingHours)}
                        label={
                            <>
                                <TimeWindowIcon
                                    className={cx('icon')}
                                    fillColor={StyleGuideColorsEnum.brandAccent}
                                    strokeColor={StyleGuideColorsEnum.brandDark}
                                />
                                {t(FIELD_LABEL_T_MAP[FieldsEnum.brokerWorkingHours])}
                            </>
                        }
                        subLabel={
                            <TimeIntervalDurationPillLabel
                                className={cx('duration')}
                                isDayRelative
                                timeWindow={formik.values[FieldsEnum.brokerWorkingHours]}
                            />
                        }
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                                {t(FIELD_DESCRIPTION_T_MAP[FieldsEnum.brokerWorkingHours])}
                            </TooltipContent>
                        }
                    >
                        {(props) => (
                            <RangeSelector
                                valuesRange={BROKER_WORKING_HOURS_RANGE}
                                availableValues={BROKER_WORKING_HOURS_RANGE}
                                ranges={BROKER_WORKING_HOURS_RANGES}
                                values={formik.values[FieldsEnum.brokerWorkingHours]}
                                valueStep={MS_IN_HOUR / 2}
                                labelsConfig={labelsConfig}
                                renderLabel={(value: number) => {
                                    const relativeTime = getTimeRelativeStartDay(value);
                                    if (value && relativeTime === '00:00') {
                                        return '23:59';
                                    }

                                    return relativeTime;
                                }}
                                onSelect={props.onChange}
                            />
                        )}
                    </FormikField>
                    <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                    <HiddenSubmitButtonForKeyboardEnter />
                </div>
            </Widget>
        </form>
    );
};

export default React.memo(NewOrderParametersSettingsForm) as typeof NewOrderParametersSettingsForm;
