import {
    QueryFiltersKeysEnum,
    QueryFiltersT,
} from 'broker-admin/layouts/SpotRequestsPage/SpotRequestListPage/query-models';
import { FieldsEnum, FormValuesT } from './constants';
import {
    getDateFromDate,
    getEndDayFromDate,
    getEndDayFromDateZero,
    getStartDayFromDate,
    getStartDayFromDateZero,
} from 'common/utils/time';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {};

    const createdByShipperId = values[FieldsEnum.createdByShipperId];
    if (createdByShipperId) {
        queryFilters[QueryFiltersKeysEnum.createdByShipperId] = createdByShipperId;
        queryFilters[QueryFiltersKeysEnum.createdByShipperName] = values[FieldsEnum.createdByShipperName];
    }

    const createdByUserId = values[FieldsEnum.createdByUserId];
    if (createdByUserId) {
        queryFilters[QueryFiltersKeysEnum.createdByUserId] = createdByUserId;
        queryFilters[QueryFiltersKeysEnum.createdByUserName] = values[FieldsEnum.createdByUserName];
    }

    const dictTrailerTypeId = values[FieldsEnum.dictTrailerTypeId];
    if (dictTrailerTypeId) {
        queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId] = dictTrailerTypeId;
    }

    const address = values[FieldsEnum.address];
    if (address) {
        queryFilters[QueryFiltersKeysEnum.address] = address;
        queryFilters[QueryFiltersKeysEnum.searchText] = undefined;
    }

    const pickupDates = values[FieldsEnum.pickupDates];
    if (pickupDates?.from) {
        const date = getDateFromDate(pickupDates.from);
        queryFilters[QueryFiltersKeysEnum.pickupDateFrom] = getStartDayFromDateZero(date);
    }

    if (pickupDates?.to) {
        const date = getDateFromDate(pickupDates.to);
        queryFilters[QueryFiltersKeysEnum.pickupDateTo] = getEndDayFromDateZero(date);
    }

    const dropoffDates = values[FieldsEnum.dropoffDates];
    if (dropoffDates?.from) {
        const date = getDateFromDate(dropoffDates.from);
        queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] = getStartDayFromDateZero(date);
    }

    if (dropoffDates?.to) {
        const date = getDateFromDate(dropoffDates.to);
        queryFilters[QueryFiltersKeysEnum.dropOffDateTo] = getEndDayFromDateZero(date);
    }

    const createDates = values[FieldsEnum.createDates];
    if (createDates?.from) {
        const date = getDateFromDate(createDates.from);
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = getStartDayFromDate(date);
    }

    if (createDates?.to) {
        const date = getDateFromDate(createDates.to);
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = getEndDayFromDate(date);
    }

    return queryFilters;
};

export default getQueryFilters;
